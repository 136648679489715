import React, { useState } from "react";
import "./NewLibraryCard.css";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../functions/useWindow";
function NewLibraryCard({ image, title, id, article, date }) {
  const [hover, setHover] = useState(false);
  const {width} =useWindowDimensions()
  const slice = (string, characterLimit) => {
    if (string.length <= characterLimit) {
      return string;
    }

    return string.slice(0, characterLimit) + "...";
  };
  const location = useLocation();
  const handleHover = () => {
    if(width>800){setHover(true);}
  };
  const handleHoverOut = () => {
    setHover(false);
  };
  return (
    <div className="sslibrary-card" onMouseOver={handleHover} onMouseOut={handleHoverOut} onTouchStart={handleHoverOut}>
      <div className="sslc-image">
        <img src={image} alt="sImage" />
        <div className="sslc-text">
          <h3>{hover? title : (width<1000 && width>700)? slice(title, 39):slice(title, 59)}</h3>

          <h5>{dateFormat(date, "dS mmmm yyyy")}</h5>
        </div>
      </div>
      <div className="sslc-description">
        <p className="sslc-short">{slice(article, 190)}</p>
        {location.pathname === "/library" ? (
          <h5 className="sslc-read-more">
            <Link to={`/a-blog?id=${id}`} className="link sslc-read-more">
              {" "}
              Read More
            </Link>
          </h5>
        ) : (
          <h5 className="sslc-read-more">
            <Link to={`/blog?id=${id}`} className="link sslc-read-more">
              {" "}
              Read More
            </Link>
          </h5>
        )}
      </div>
    </div>
  );
}

export default NewLibraryCard;
