import React, {useEffect, useState} from "react";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import ServiceCard from "../components/ServiceCard"; 
import "./Treatments.css"; 
import axios, { newAbortSignal } from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {    faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as constants from "../utils/constants.js"

import NavElementTraining from "../components/NavBar/NavElementTraining";
import GoToTop from "../functions/GotToTop";
import { getLandingPage } from "../api/prismic";
function OurTreatments() {
  const { height, width } = useWindowDimensions();
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
 

/// GET COURSES
const [services, setServices] = useState([]);
const TREATMENTS_URL = "/api/treatments?populate=*";
useEffect(() => {
  
    try {
      if (localStorage.getItem("treatments") !== null) {
        var retrievedObject = localStorage.getItem("treatments");
        console.log(JSON.parse(retrievedObject))

        setServices(JSON.parse(retrievedObject));
      } else if (
        localStorage.getItem("treatments") === null
      ) { 
        const fetchData = async () => {
          await getLandingPage().then((response) => { 
        console.log(response.filter((e) =>  e.type === "services")[0].data.list, "LLLLLLLLLLLLLLLLLLLLLLLLLLL")

        setServices(response.filter((e) =>  e.type === "services")[0].data.list);

          })
        }
        fetchData();
      }
    } catch (e) {
       
    }
  
}, []);

  return (
    <>
      <NavElementTraining/>
      <div className="dark-60-bar" />
      <div className="header-gap" />

        <div className="treatment-page">
      <div className="sectionTitle">
        <h2>Services</h2>
        {!services.length && width >= 700 ?  (
          <div className="loading">
           
            {spinner} {spinner} {spinner}
          </div>
        )  : null}
        {!services.length && width < 700 ? (
          <div className="loading"> {spinner}</div>
        ) : null}
      </div>
      <div className="services-card-desktop">
      <div className="hugger">
        {services.map((e) => {
              return (
                // <CardForCourse
                //   image={e.attributes.image.data.attributes.url}
                //   // image={handwash}
                //   title={e.attributes.title}
                //   detail={e.attributes.shortDetail}
                //   id={e.id}
                // />
                <ServiceCard
                // image={e.attributes.image.data.attributes.formats.large.url}
                image={e.image.url}

                title={e.title[0].text}
                detail={e.short_description[0].text}
                id={services.indexOf(e)}
                background=" #166473" 
              />
              );
            })}</div>
      </div>
       
       {/* { width>=700&& */}
       <><br/>
        <br/></>
        {/* } */}
      </div>
      <CTA buttonType={1}/>

      {width >= 1000 ? (
        <DesktopFooter address={"/book"} action={"BOOK NOW"} />
      ) : (
        <Footer />
      )}
      <GoToTop/>

    </>
  );
}

export default OurTreatments;
