import React, { useRef, useState, useLayoutEffect } from "react";
import Slider from "react-slick";  
import "./MobileCarousalHero.css";
import "./GlobalHero.css";
import "./CarousalHero.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import MobileTogler from "./mobleToggler";
import CustomizedDialogs from "./MuiDialog";
import BookDialogs from "./BookDialog";
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
function MobileCarousalHero({ header, training }) {
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  const sliderRef = useRef();
  const textRef = useRef();
  const [page, setPage] = useState(1);
  const [current, setCurrentPage] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    centerPadding: "0px",
    beforeChange: (current, next) => {
      setCurrentPage(next);
    },
    onSwipe: () => {
      if (page == 2) {
        setPage(1);
      }
      if (page == 1) {
        setPage(2);
      }
    },
  };

  const onNext = () => {
    sliderRef.current.slickNext();
    if (page == 2) {
      setPage(1);
    }
    if (page == 1) {
      setPage(2);
    }
  };
  const onBack = () => {
    sliderRef.current.slickPrev();
    if (page == 2) {
      setPage(1);
    }
    if (page == 1) {
      setPage(2);
    }
  };

  const getLastWord = (words) => {
    var n = words.split(" ");
    return n[n.length - 1].toUpperCase();
  };
  const removeLastWord = (words) => {
    var n = words.trim().split(" ");
    var m = n.slice(0, -1);
    return m.join(" ");
  };
  const [visibelText, setVisibelText] = useState(false);

  useLayoutEffect(() => {
    if (header.length > 0) {
      setTimeout(() => {
        setVisibelText(true);
      }, 500);
    }
    //
  }, [header.length]);
  return (
    <>
      <div className="fit-width">
        <Slider ref={sliderRef} {...settings} className="slick-slider-destroy-gap">
          {header.length &&
            header.map((e) => {
              return (
                <div className="mnew-hero-layout ">
                  <div className="dark-60-bar"></div>
                  <div className="shadow"></div>
                  <div className="mnh-image">
                    <img src ={e.image.url}
 />
                    {/* <img src={e.attributes.image.data.attributes.url} /> */}
                  </div>
                  <div
                    className={
                      visibelText ? "mnh-text visibleCarouselText" : "mnh-text"
                    }
                    ref={textRef}
                  >
                    <h3>
                      {removeLastWord(e.text[0].text)}<span> {getLastWord(e.text[0].text)}</span>
                    </h3> 
                   
                    {/* <MobileTogler
                      onForward={() => onNext()}
                      onBack={() => onBack()}
                      place={page}
                      current={current}
                      total={header.length ? header.length : 0}
                    /> */}
                    <div style={{
                      padding:" 12px 0 46px 10px"
                    }}>
                    {
                      training?
                      <CustomizedDialogs
                      white={true}
                      />:
                      <BookDialogs 
                      white={true}
                      />
                    }</div>
                  </div>
                </div>
              );
            })}
          {!header.length && (
            <div className="mloading-header ">
              <div className="loading"> 
                {spinner} 
              </div>
            </div>
          )}
        </Slider>
        {/* <div className="mab-toggler">
        <Togler 
          onForward={() => onNext()}
          onBack={() => onBack()}
          place={page}
          current={current}
          total={header.length ? header.length : 0}
        /></div> */}
      </div>
    </>
  );
}

export default MobileCarousalHero;
