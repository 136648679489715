import React, {useRef, useEffect, useState} from 'react'
import SingleServiceTestimony from './SingleServiceTestimony';
import Slider from 'react-slick';
import "react-responsive-carousel/lib/styles/carousel.min.css";   
import Togler from "../Togler";
import "../GlobalHero.css";
import "../Testimonials.css"; 
import useWindowDimensions from "../../functions/useWindow"; 
import { GetMediumImage } from '../../functions/GetTheRightImageQuality';

function ServiceTestimonySlider({testimonies}){
    const sliderRef = useRef();
  const [page, setPage] = useState(1);
  const [current, setCurrentPage] = useState(0);
  const [slideNumber, setSlideNumber] = useState();
  const [centerMode, setCenterMode] = useState();
  const { width } = useWindowDimensions();

  var settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    // speed: 500,
    centerMode: false,
    slidesToShow: slideNumber,
    slidesToScroll: 1,
    draggable: true,
    swipeToSlide: true,
    autoplay : true,
    autoplaySpeed: 6000,
    pauseOnHover:true, 
    beforeChange: (current, next) => {
      setCurrentPage(next);
    },
    onSwipe: () => {
      if (page === 2) {
        setPage(1);
      }
      if (page === 1) {
        setPage(2);
      }
    },
  };

  const onNext = () => {
    sliderRef.current.slickNext();
    if (page === 2) {
      setPage(1);
    }
    if (page === 1) {
      setPage(2);
    }
  };
  const onBack = () => {
    sliderRef.current.slickPrev();
    if (page === 2) {
      setPage(1);
    }
    if (page === 1) {
      setPage(2);
    }
  };
  useEffect(() => {
   if (width > 700 && testimonies.length>=2) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(false);

      setSlideNumber(1);
    }
  }, [width]);
  useEffect(() => {
     if (width > 700 && testimonies.length>=2) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(false);

      setSlideNumber(1);
    }
  }, []);
 

  
  useEffect(() => {
     if (width > 700 && testimonies.length>=2) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(false);

      setSlideNumber(1);
    }
  }, [testimonies]);
  const handleOnTouch = () =>{
    console.log("held")
    sliderRef.current.slickPause();
  }
  const handleOnReleaseTouch = () =>{
    console.log("released")
   
    sliderRef.current.slickPlay();
  }
  return (
    
      <div className="testimonials">
      <h2 className="center"> Results</h2>
      <div
        className="fit-testamony"
        onTouchEnd={handleOnReleaseTouch}
        style={{ 
            width: width < 930 ? "100%" : "100%"
            , maxWidth:"1200px"
        // , border:"2px solid red" ,
        // display:"flex", 
        // flexDirection:"column",
        // alignItems:"Center",
        //     justifyContent:"center !important" 
    }}
      >
        
        <Slider 
        // onTouchStart={handleOnTouch}
        ref={sliderRef} {...settings} style={{
            // border:"2px solid red",
            // width:"100vw !important",
            // display:"flex", 
            // justifyContent:"center !important" ,
            // alignItems:"center"
            }}>
          {testimonies.map((e) => {
             return (
              <SingleServiceTestimony
                onTouch={handleOnTouch}
                name={e.title[0].text}
                text={e.detail[0].text}
                // before={e.attributes.before.data.attributes.url}
                before= {e.before.url}

                // after={e.attributes.after.data.attributes.url}
                after= {e.after.url}

                // rate={e.attributes.rating}
                id={e.id}
              />
            // <h1>asdfas</h1> 
            );
          })}
        </Slider> 
        <br />
        <br />
      
    <Togler onForward={()=>onNext()} onBack={()=>onBack()} place={page}  current={current} total={testimonies.length?testimonies.length:0} />

        <br />
        <br />
      </div>
    </div>  
  )
}

export default ServiceTestimonySlider