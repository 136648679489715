import axios, { newAbortSignal, prismicAxios } from "./axios";
import * as constants from "../utils/constants.js" 

export const getRef = async () => {
    await prismicAxios
        .get("https://serumet.cdn.prismic.io/api/v2", {
            signal: newAbortSignal(constants.TIMEOUT_LONG),
        })
        .then((response) => {
            console.log(response.data.refs[0].ref, "OOOOOOOOOOOOOOOOOOOOOOOOO")
            return response.data.refs.ref

        })
        .catch((e) => {
            console.log(e);   //// prepare a defaul image and text here and set it to homepage
        });
}

export const getLandingPage = async () => {
    var results;

    // GET PRISMIC MASTER REFERENCE
    await prismicAxios
        .get("https://serumet.cdn.prismic.io/api/v2", {
            signal: newAbortSignal(constants.TIMEOUT_LONG),
        })
        // GET ALL PRISMIC DATA 
        .then(async (response) => {
            const HOMEPAGE_URL = `/search?ref=${response.data.refs[0].ref}&q=%5B%5Bat(document.type%2C%22landing_page%22)%5D%5D`;
            const PRISMIC_URL = `https://serumet.prismic.io/api/v2/documents/search?ref=${response.data.refs[0].ref}`
            await prismicAxios
                .get(PRISMIC_URL, {
                    signal: newAbortSignal(constants.TIMEOUT_LONG),
                })
                .then((response) => {
                    results = response.data.results;

                })
                .catch((e) => {

                    console.log(e);
                    return "-1"
                });

        })
        .then(()=>{   getImagesUrls(results) })
        //RECORD ALL DATA IN CACHE IN THEIR RESPECTIVE CATEGORY
        .then(() => {
            //RECORD LANDING PAGE DATA
            localStorage.setItem(
                "home-page",
                JSON.stringify(results.filter((e) => e.type === "landing_page")[0].data)
            );
            //RECORD SERVICE HERO DATA
            localStorage.setItem(
                "service-header",
                JSON.stringify(results.filter((e) => e.type === "service_desktop_header")[0].data.list)
            );
            // RECORD TRAINING HERO DATA
            localStorage.setItem(
                "training-header",
                JSON.stringify(results.filter((e) => e.type === "training_desktop_header")[0].data.list)
            );
            //RECORD MOBILE SERVICE HERO DATA
            localStorage.setItem(
                "service-mobile-header",
                JSON.stringify(results.filter((e) => e.type === "service_mobile_header")[0].data.list)
            );
            // RECORD MOBILE TRAINING HERO DATA
            localStorage.setItem(
                "training-mobile-header",
                JSON.stringify(results.filter((e) => e.type === "training_mobile_header")[0].data.list)
            );
            //RECORD COURSES DATA
            localStorage.setItem(
                "courses",
                JSON.stringify(results.filter((e) =>  e.type === "trainings")[0].data.training_list)
            );
            //RECORD TREATMENTS DATA
            localStorage.setItem(
                "treatments",
                JSON.stringify(results.filter((e) =>  e.type === "services")[0].data.list)
            );
            //RECORD SERVICE LIBRARY DATA
            localStorage.setItem(
                "service_library",
                JSON.stringify(results.filter((e) =>  e.type === "service_library")[0].data.list)
            );
            //RECORD TRAINING LIBRARY DATA
            localStorage.setItem(
                "training_library",
                JSON.stringify(results.filter((e) =>  e.type === "training_library")[0].data.list)
            );
            //RECORD TESTIMONIALS DATA
            localStorage.setItem(
                "testimony",
                JSON.stringify(results.filter((e) =>  e.type === "testimony")[0].data.list)
            );
            //RECORD BEFORE AND AFTER DATA
            localStorage.setItem(
                "result",
                JSON.stringify(results.filter((e) =>  e.type === "result")[0].data.list)
            );
        })
        .catch((e) => {
            return "-1"
        });  

    //RETURN ALL PRISMIC DATA
    return results

}


export const getImagesUrls =   (prismic_data) => {
    var images=[];
    images.push(prismic_data.filter((e) => e.type === "landing_page")[0].data.service_image.url)
    images.push(prismic_data.filter((e) => e.type === "landing_page")[0].data.training_image.url)
    prismic_data.filter((e) => e.type === "service_desktop_header")[0].data.list.map((e) => images.push(e.image.url))
    prismic_data.filter((e) => e.type === "training_desktop_header")[0].data.list.map((e) => images.push(e.image.url))
    
    // return images;
      for(var i=0; i<images.length;i++){
        preloadImage(images[i])
      }
    // console.log("SSSSSSSSSSSSSSSSS")
    console.log("IMAGE LOADED")

}
 
const preloadImage = (src) => new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    window[src] = image;
    image.style.display = 'none'
    image.onload = () => {
      document.body.removeChild(image)
      resolve()
    }
    image.onerror = reject
    document.body.appendChild(image)
  })
