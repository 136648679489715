import React from 'react'
import "./SlidLibraryCard.css" 
import dateFormat from 'dateformat'
function SlidLibraryCard({image, title, id, swapPage, date}) {
  return (
    <div className='slibrary-card'   >
        <div className='slc-image'>
            <img src={image} alt='Image'/>
        </div>
        <div className='slc-text' onClick={()=>swapPage(id)}>
            {/* <Link to={`/blog?id=${id}`} className='link'></Link> */}
            <h3>{title}</h3>
            <h5>{dateFormat(Date(date), "dS mmmm yyyy")}</h5>
        </div>
    </div>
  )
}

export default SlidLibraryCard