import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/big-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faBars,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import "./ServiceNavBar.css";
import Button from "../Button";
import useWindowDimensions from "../../functions/useWindow";
import CustomizedDialogs from "../MuiDialog";
import BookDialogs from "../BookDialog";
// import useWindowDimensions from '../functions/useWindow'

function ServiceNavBar({ first, second, third, fourth, fifth, sixth, button }) {
  const element = <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />;
  const menuBar = <FontAwesomeIcon icon={faBars} size="2xl" />;
  const closeBar = <FontAwesomeIcon icon={faX} size="2xl" />;
  const [showMenu, setShowMenu] = useState();
  const location = useLocation();
  const clickShowMenu = () => {
    setShowMenu(!showMenu);
    const closeBar = <FontAwesomeIcon icon={faX} />;
  };
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width >= 1000 ? (
       
          <div className="desktop-last-header">
            <nav className="desktop-nav-bar">
              <div className="sv-desktop-nav-links ">
                <Link to="/" className="link">
                  <img src={logo} alt="logo" className="serum-logo" />
                </Link>
                <ul style={{ padding: !fifth && "0 70px" }}>
                  <li
                    style={{
                      paddingBottom:
                        location.pathname === first.address ? "15px" : "0",
                      // marginTop: "40px !important",
                      // border: "2px solid red",
                    }}
                  >
                    <Link
                      to={first.address}
                      className="menuLink"
                      style={{
                        textDecoration:
                          location.pathname === first.address
                            ? "underline "
                            : "none",
                        textUnderlineOffset: "13px ",
                        // marginBottom:"20px",
                        //   border: "2px solid red",
                      }}
                    >
                      {first.name}
                    </Link>
                  </li>
                  <li
                    style={{
                      paddingBottom:
                        location.pathname === second.address //first.address
                          ? "15px"
                          : "0",
                      // marginTop: "40px !important",
                      // border: "2px solid red",
                    }}
                  >
                    <Link
                      to={second.address}
                      className="menuLink"
                      style={{
                        textDecoration:
                          location.pathname === second.address
                            ? "underline "
                            : "none",
                        textUnderlineOffset: "13px ",
                        // marginBottom:"20px",
                        //   border: "2px solid red",
                      }}
                    >
                      {second.name}
                    </Link>
                  </li>
                  <li
                    style={{
                      paddingBottom:
                        location.pathname === third.address  ||
                        location.pathname === "/treatment"||
                        location.pathname === "/program"
                          ? "15px"
                          : "0",
                    }}
                  >
                    <Link
                      to={third.address}
                      className="menuLink"
                      style={{
                        textDecoration:
                          location.pathname === third.address ||
                          location.pathname === "/treatment"||
                          location.pathname === "/program" //first.address
                            ? "underline "
                            : "none",
                        textUnderlineOffset: "13px ",
                        // marginBottom:"20px",
                        //   border: "2px solid red",
                      }}
                    >
                      {third.name}
                    </Link>
                  </li>
                  <li
                    style={{
                      paddingBottom:
                        location.pathname === fourth.address ||
                        location.pathname === "/blog" ||
                        location.pathname === "/a-blog"//first.address
                          ? "15px"
                          : "0",
                      // marginTop: "40px !important",
                      // border: "2px solid red",
                    }}
                  >
                    <Link
                      to={fourth.address}
                      className="menuLink"
                      style={{
                        textDecoration:
                          location.pathname === fourth.address ||
                          location.pathname === "/blog" ||
                          location.pathname === "/a-blog"//first.address
                            ? "underline "
                            : "none",
                        textUnderlineOffset: "13px ",
                        // marginBottom:"20px",
                        //   border: "2px solid red",
                      }}
                    >
                      {fourth.name}
                    </Link>
                  </li>
                  {fifth && (
                    <li
                      style={{
                        paddingBottom:
                          location.pathname === fifth.address||
                          location.pathname === "/a-treatment" //first.address
                            ? "15px"
                            : "0",
                        // marginTop: "40px !important",
                        // border: "2px solid red",
                      }}
                    >
                      <Link
                        to={fifth.address}
                        className="menuLink"
                        style={{
                          textDecoration:
                            location.pathname === fifth.address||
                            location.pathname === "/a-treatment" //first.address
                              ? "underline "
                              : "none",
                          textUnderlineOffset: "13px ",
                          // marginBottom:"20px",
                          //   border: "2px solid red",
                        }}
                      >
                        {fifth.name}
                      </Link>
                    </li>
                  )}
                  {fifth && (
                    <li
                      style={{
                        paddingBottom:
                          location.pathname === sixth.address //first.address
                            ? "15px"
                            : "0",
                        // marginTop: "40px !important",
                        // border: "2px solid red",
                      }}
                    >
                      <Link
                        to={sixth.address}
                        className="menuLink"
                        style={{
                          textDecoration:
                            location.pathname === sixth.address //first.address
                              ? "underline "
                              : "none",
                          textUnderlineOffset: "13px ",
                          // marginBottom:"20px",
                          //   border: "2px solid red",
                        }}
                      >
                        {sixth.name}
                      </Link>
                    </li>
                  )}

                  {/* <li className="search">{element}</li> */}
                </ul>
                {/* <Button
                  className="btn "
                  children={button.name}
                  buttonColor="btn--dark"
                  buttonSize="btn--small"
                  address={button.address}
                /> */}
               < div className="navbarbutt"> {fifth ? (
                  <CustomizedDialogs small={true} />
                ) : (
                  <BookDialogs small={true} />
                )}</div>
              </div>
            </nav>
          </div>
        // </section>
      ) : (
        <section className="mobile-nav-bar mobile">
          <Link
            to="/"
            className="link"
            style={showMenu ? { alignSelf: "flex-start" } : {}}
          >
            <img src={logo} alt="logo" className="serum-logo" />
          </Link>
          {/* {(!showMenu && fifth)&& <CustomizedDialogs header={true}/>}
          {(!showMenu && !fifth)&& <BookDialogs header={true}/>} */}
          <div className="nav-links">
            <ul>
              {!showMenu && (
                <li className="menuBar" onClick={clickShowMenu}>
                  {menuBar}
                </li>
              )}
            </ul>
          </div>

          {showMenu && (
            <Menu
               
              treatment={sixth ? false : true}
              closeMenuFunction={() => clickShowMenu()}
            />
          )}
        </section>
      )}
      {/*  {width>=1000?
        <section className="sv-desktop">
          <header>
           <nav className="sv-desktop-nav-bar">
            asdfhajkhj 
               <Link to="/" className="link">
                <img src={logo} alt="logo" className="serum-logo" />
              </Link>
              <div className="desktop-nav-links ">
                <ul>
                  <li   style={{ 
                         alignSelf:location.pathname ===first.address? "start !important":"center",
                         marginTop: "40px !important",
                         border: "2px solid red",
                
                      }}>
                    <Link
                      to={first.address}
                      className="menuLink"
                      style={{ 
                        textDecoration:location.pathname ===first.address? "underline ":"none",
                        textUnderlineOffset: "20px ",
                        // marginBottom:"20px",
                        border: "2px solid red",
                
                      }}
                    >
               
                   {first.name}  
                    </Link> 
                  </li>
                  <li  >
                    <Link to="/services" className="menuLink"
                    style={{ textDecoration: location.pathname === `/services`? "underline":"none",
                    textUnderlineOffset: "9px ",}}>
                      Services
                    </Link> 
                  </li>
                  <li>
                    <Link to="/training" className="menuLink"
                        style={{ textDecoration:location.pathname ==="/training"? "underline ":"none",
                        textUnderlineOffset: "9px ",}}>
                      Training
                    </Link> 
                  </li>
                  <li>
                    <Link to="/" className="menuLink">
                      Blogs
                    </Link> 
                  </li>
                  <li>
                    <Link to="/" className="menuLink">
                      Contacts
                    </Link> 
                  </li>
                  <li className="search">{element}</li>
                  <li className="nav-bar-button">
                    <Button
                      className="btn "
                      children="Contact Us"
                      buttonColor="btn--dark"
                      buttonSize="btn--small"
                    />
                  </li>
                </ul>
              </div>
            </nav> 
          </header>
        </section>:
        <section className="mobile-nav-bar mobile">
          <header>
            <nav> 
              <Link to="/" className="link">
                <img src={logo} alt="logo" className="serum-logo" />
              </Link>
              <div className="nav-links">
                <ul>
                  <li
                    className="search"
                    style={
                      showMenu
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    {element}
                  </li>
                  {!showMenu && (
                    <li className="menuBar" onClick={clickShowMenu}>
                      {menuBar}
                    </li>
                  )}
                </ul>
              </div>
            </nav>
            {showMenu && <Menu closeMenuFunction={() => clickShowMenu()} />}
          </header>
        </section>}*/}
    </>
  );
}

export default ServiceNavBar;
