import React from "react";
import "./CardForCourse.css"
import { Link } from "react-router-dom";
function CardForCourse(props) {
  const slice = (string, characterLimit) => {
    if (string.length <= characterLimit) {
      return string;
    }
  
    return string.slice(0, characterLimit) + "...";
  };
  return (
    <div className="card-for-course">
      <div className="cfc-img">
        <img src={props.image} alt="cfc-img" />
      </div>

      <div className="cfc-title">
        <h3>{props.title}</h3>
      </div>
      <div className="cfc-text">
        <p>{
        props.detail }</p>
        <Link to={`/program?id=${props.id}`} style={{textDecoration:"none"}} className="no_highlights" 
                target="_blank" rel="noopener noreferrer" 
        
        >
        <h4 className="cfc-learn-more">Learn more</h4></Link>
      </div>
    </div>
  );
}

export default CardForCourse;
