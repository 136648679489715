import React from "react";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import aboutImage from "./assets/about.png";
import signiture from "./assets/signiture.png";
import doctor from "./assets/doctor.png";
import emp1 from "./assets/emp1.png";
import emp0 from "./assets/b.png";

import "./About.css";
import { useLocation } from "react-router-dom";
import NavElementService from "../components/NavBar/NavElementService";
import GoToTop from "../functions/GotToTop";
import SingleServiceTestimony from "../components/beforeAfter/SingleServiceTestimony";
// import BeforeAfter from "../components/beforeAfter/BeforeAfter.js";
function ServiceAbout({training}) {
  const { height, width } = useWindowDimensions();
  const location = useLocation()
  return (
    <>
        <NavElementService/>
      <div className="dark-60-bar" />
      <div className="header-gap-smaller" />
{width < 700 && <div className="header-gap-not-smaller" />}
{width < 700 && <div className="header-gap-not-smaller" />}
{width < 700 && <div className="header-gap-not-smaller" />}
{width < 700 && <div className="header-gap-not-smaller" />}
    {/* <SingleServiceTestimony name={"betty"} rate={4} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget metus elementum, fermentum nisi eu, laoreet mauris. Vivamus porttitor, ante ut aliquam aliquam, mi orci tempor purus, at congue urna quam eu justo. Donec sodales enim magna, vitae tincidunt orci mattis ac."}/> */}
<div className="about-page">
        <div className="about-serum">
          <div className="as-image">
            {/* <img src={aboutImage} alt="Image" /> */}
            <iframe
        
        style={{
          position: "relative", 
          // zIndex:"-99999",
      display:'block',
      border: "0px"

        }}
      // width={"200%"}
      // height={"100%"}
      src="https://killerplayer.com/watch/video/4abfec36-f78a-4e62-90ac-283f19ee915e"
 
      allow="gyroscope; picture-in-picture;"
      title="video"
    />
          </div>
          <div className="as-texts">
            <div className="as-title">
              <h1>About Serum</h1>
            </div>
            <div className="as-about-para">
            <p>
                <span>Welcome </span> to Serum Skin Care Salon & Training Center, a renowned institution based in Addis Ababa, Ethiopia, specializing in comprehensive training in the field of medical aesthetics. Founded in 2021 by Dr. Hassina Abdurhaman, a highly skilled medical doctor and Licensed Aesthetician, our center is dedicated to providing exceptional education and practical skills to aspiring professionals in the aesthetics industry. Our Center offers an integrated medical aesthetics education provides practical training in various areas like skincare and non-surgical procedures. The center emphasizes hands-on learning with state-of-the-art facilities. Graduates receive ongoing support and join a community of professionals advancing aesthetic medicine.


              </p>
              <p>
                <span>In summary,</span>  Serum Training Center in Addis Ababa, Ethiopia, offers a premier educational experience in the field of medical aesthetics. With our commitment to excellence, practical training approach, and highly qualified faculty, we stand as a leading institution in the region, producing skilled professionals who contribute to the growth and development of the aesthetics industry. Join us at Serum Training Center and embark on a rewarding journey towards a successful career in medical aesthetics.
              </p>
            </div>
          </div>
        </div>
        </div>

        <div className="founder">
          <div className="f-row-pls">
            <div className="f-texts">
              <div className="welcome">    
                        <h1> Founder & CEO</h1>

              </div>
              <div className="f-para">
              <p>
              Dr. Hassina Abdurhaman is a well-respected professional in the fields of medicine and skincare. She combines her extensive knowledge as a medical doctor with her expertise as a licensed aesthetician to provide a comprehensive and holistic approach to patient care. As the Founder and CEO of Serum Skin Care Salon & Training Center, she has built a reputation for excellence in treatments, products, and education. Her center is recognized for its commitment to ensuring individuals achieve optimal skin health and beauty. Dr. Abdurhaman's dedication to integrating medical science with aesthetics has made her a leader in the industry and her center a top destination for those seeking the very best in skincare solutions.
                </p>
              {/* <p>
                Dr. Hassina Abdurhaman, the CEO and founder of Serum Skin Care Salon & Training Center is a distinguished medical doctor and licensed aesthetician. With her expertise and unwavering dedication to aesthetics, Dr. Hassina has made remarkable strides within Addis Ababa's medical aesthetics landscape. Dr. Hassina's journey in the beauty and wellness realm began with her extensive medical background.
                </p>
                <p>
                Recognizing the crucial intersection of medical science and aesthetics, she embarked on further education to become a Licensed Aesthetician, blending her medical knowledge with advanced skincare techniques. Driven by her ambition to bridge the gap between medical science and aesthetics, Dr. Hassina established Serum Skin Care Salon & Training Center which serves as a sanctuary and haven that caters to clients seeking unparalleled skincare treatments while also serving as a nurturing hub for aspiring professionals aiming to refine their skills in the aesthetics industry.
                </p>{" "}
                <p>
                Dr. Hassina's passion for aesthetics, combined with her medical expertise, has solidified her position as a respected authority in the field. Through the establishment of Serum Skin Care Salon & Training Center, her impact on Addis Ababa's beauty and wellness industry is profound. By empowering individuals to enhance their appearance and confidence while upholding the utmost standards of professionalism and safety, she has redefined the possibilities of medical aesthetics.
                </p> */}
                <div className="signiture">
                  <img src={`https://res.cloudinary.com/dhe0yelid/image/upload/v1692877567/medium_Hassina_Signature_Asset_1_300x_2d5e60ea49.png`} alt="signiture" />
                </div>
              </div>
            </div>
            {width < 700 && <div className="header-gap-not-smaller" />}

            <div className="avatar-section">
              <div className="avatar ">
                <img src={`https://res.cloudinary.com/dhe0yelid/image/upload/v1692863134/medium_dr_9aad49654f.jpg`} alt="doctor" />
              </div>
              <div className="doc-name">
              <h2>Dr. Hassina Abdurhaman</h2>
              </div>
              <div className="doc-text">
                <p>
                Founder & CEO
                </p>
              </div>
            </div>
            {width < 700 && <div className="header-gap-not-smaller" />}

          </div>
        </div>
      <div className="about-page">

      <div className="team-section">
        <div className="t-title">
          <h1>Meet the Team</h1>
        </div>
        <div className="team">
          <div className="single-emp">
            <div className="emp-img"  >
              <img src={`https://res.cloudinary.com/dhe0yelid/image/upload/v1692863134/medium_dr_9aad49654f.jpg`} alt="employee" />
            </div>
            <div className="emp-name">
            <h2>Dr. Hassina Abdurhaman</h2>
            </div>
            <div className="emp-text">
              <p>
              Founder & CEO
              </p>
            </div>
          </div>

           


          <div className="single-emp">
            <div className="emp-img">
              <img src={`https://res.cloudinary.com/dhe0yelid/image/upload/v1692863135/medium_loza_06dace448e.jpg`} alt="employee" />
            </div>
            <div className="emp-name">
              <h2>Loza Michael</h2>
            </div>
            <div className="doc-text">
              <p>
              Skincare Specialist
              </p>
            </div>
          </div>
           

          <div className="single-emp">
            <div className="emp-img">
              <img src={`https://res.cloudinary.com/dhe0yelid/image/upload/v1692863135/medium_mitu_8557390b2c.jpg`} alt="employee" />
            </div>
            <div className="emp-name">
              <h2>Betty Dereje</h2>
            </div>
            <div className="doc-text">
              <p>
              Skincare Specialist
              </p>
            </div>
          </div>
        </div>
      </div>
</div>
      <CTA buttonType={2}/>

      {width >= 1000 ? (
        <DesktopFooter address={"/book"} action={"BOOK NOW"} />
      ) : (
        <Footer />
      )}
      <GoToTop/>

    </>
  );
}

export default ServiceAbout;
