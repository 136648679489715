import React, { useRef, useEffect } from "react";
import "./BeforeAfter.css";
import A from "../../scenes/assets/a.png";
import h from "../assets/h.png";
import s from "../assets/s.png";
import B from "../../scenes/assets/b.png";
import L from "../assets/big-logo.png";
import arr from "../assets/arr.png";
import { ReactComponent as Sarr } from "../assets/arrg.svg";
import { Rnd } from "react-rnd";
function BeforeAfter({before, after, onTouch}) {
  const ref = useRef(null);

  const refRight = useRef(null);

  const handleResizeStop = (e, dir, refToElement, delta, position) => {
    e.preventDefault();
  };

  return (
    <div className="before-after-container">
      <div className="before-after-parent">
        <img src={after} alt="emp" />
        <Rnd
          maxWidth={"90%"}
          minWidth={"10%"}
          enableResizing={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          resizeHandleComponent={{ right: <Handler onTouch={onTouch} /> }}
          disableDragging={true}
          style={{

            position:"inherit"
          }}
          default={{
            x: 0,
            y: 0,
            width:"50%",
            height: { Infinity },
          }}
        >
          <div className="rnd-aaa">
            <img src={before} alt="doc" style={{width:"400px"}} />
          </div>
        </Rnd>
      </div>
    </div>
  );
}

export default BeforeAfter;

const Handler = ({ ref, direction, event, onTouch }) => {
  const handleRef = useRef();

  // useEffect(() => {
  // const handle = handleRef.current;

  //   handle.addEventListener('mouseover', () => {
  //     handle.style.backgroundColor = 'rgba(81, 168, 177, 0.9)';
  //   });
  //   handle.addEventListener('mouseout', () => {
  //     handle.style.backgroundColor = 'rgba(81, 168, 177, 0.6)';
  //   });
  //   handle.addEventListener('mousemove', () => {
  //     handle.style.backgroundColor = 'rgba(81, 168, 177, 0.9)';
  //   });
  //   // return handle.removeEventListener('mouseover',()=>{})
  // }, [ ]);

  return (
    <div
      ref={handleRef}
      className="before-after-resizer"
      style={{
        height: "350px",
        width: "5px",
        filter: " drop-shadow(0px 0px 0.7px #000)",
      }}
    >
      <div
      onTouchStart={()=>onTouch()}
        className="img"
        //  style={{ backgroundImage: `url(${L})` }}
      >
        <div className="arrr">
          {/* <div className="leftArr"
           style={{ backgroundImage: `url(${arr})` }}
           > */}
            <Sarr className="leftArr  "/>
            {/* <img src={arr} alt="arrow" className="leftArr"/> */}
          {/* </div> */}

          {/* <div className="rightArr"
           style={{ backgroundImage: `url(${arr})` }}
           > */}
            <Sarr className="rightArr"/>
            {/* <img src={arr} alt="arrow"  className="rightArr"/> */}
          {/* </div> */}
        </div>
      </div>
      <hr />
    </div>
  );
};
