import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ServiceCard(props) {
  const location = useLocation();

  return (
    <>
      <div className="serviceCardContainer1">
        <div className="image">
          <div className="gedi-filter" />
          <div className="sshadow" />
          <img src={props.image} alt="courseImage" />
        </div>
        <div className="text">
          <div className="Title serviceTitle">
            <h3>{props.title}</h3>
          </div>
          {(location.pathname === "/treatments"||location.pathname === "/services") ? (
            <div className="card-text service-card-text">
              <p>{props.detail}</p>
              <Link
                to={`/treatment?id=${props.id}`}
                className="learn-more service-learn-more no_highlights"
                style={{ textDecoration: "none" }}
                target="_blank" rel="noopener noreferrer" 
              >
                {" "}
                <h4 className="learn-more service-learn-more">Learn more</h4>
              </Link>
            </div>
          ) : (
            <div className="card-text service-card-text">
              <p>{props.detail}</p>
              <Link
                to={`/a-treatment?id=${props.id}`}
                className="learn-more service-learn-more no_highlights"
                style={{ textDecoration: "none" }}
                target="_blank" rel="noopener noreferrer" 
              >
                {" "}
                <h4 className="learn-more service-learn-more">Learn more</h4>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
