import * as React from "react";
import PropTypes from "prop-types";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { ElfsightWidget } from "react-elfsight-widget";
import Button from "./Button";
import OnClickButton from "./OnClickButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div className="dialog-tit">
        <h1> Appointment Request Form</h1>
        <a
          // aria-label="close"
          onClick={onClose}
          // sx={{
          //   position: "absolute",
          //   right: 8,
          //   top: 8,
          //   color: (theme) => theme.palette.grey[500],
          // }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </a>
      </div>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BookDialogs({ small, white, header }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dialog-container">
      {header ? (
        <h1 onClick={handleClickOpen} className="header-button">
          BOOK NOW
        </h1>
      ) : (
        <OnClickButton
          onClick={handleClickOpen}
          className="btn desktop-button"
          children="BOOK NOW"
          buttonColor={white ? "btn--white" : "btn--dark"}
          // buttonSize={ "btn--small"}
          buttonSize={small ? "btn--small" : "btn--big"}
          address="/register"
        />
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <div className="form-padding  uidio">
          {/* <IconButton
          className="elf-z-top5"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {"x"}
        </IconButton> */}
          <div className="loading-form-elf">
            <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
            <h4>Loading Form, Please wait...</h4>
          </div>
          <div
            style={{
              background: "white",
              zIndex: "2 !important",
              position: "relative",
            }}
          >
            <ElfsightWidget
              style={{ fontFamily: "Inter", width: "100%" }}
              widgetId={"d64bda1b-7815-4fc6-8855-dc7e15f20e71"}
            />
          </div>
          {/* {wid} */}
          {/* {elf} */}
        </div>
      </BootstrapDialog>
    </div>
  );
}
