import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularfaStar } from "@fortawesome/free-regular-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../functions/useWindow";
import BeforeAfter from "./BeforeAfter";
import "./SingleServiceTestimony.css";

function SingleServiceTestimony({ name, rate, text, before, after, onTouch }) {
  const star = <FontAwesomeIcon icon={faStar} />;
  const regularStar = <FontAwesomeIcon icon={regularfaStar} />;
  const sstar = <FontAwesomeIcon icon={faStar} size="xs" />;
  const sregularStar = <FontAwesomeIcon icon={regularfaStar} size="xs" />;
  const quote = <FontAwesomeIcon icon={faQuoteRight} />;
  const { height, width } = useWindowDimensions();

  return (
    <div className="pleaseCenterUp">
      <div className="single-service-testimony">
        <BeforeAfter before={before} after={after} onTouch={onTouch}/>

        <div className="service-testimony-text">
          <div className="before-after-sign">
            <h4>Before</h4>
            <h4>After</h4>
          </div>
          {/* <div className="quoteIcon"> {quote}</div> */}

          <div className="service-testifier">
            <h5>{name}</h5>
          </div>
          {/* {width >= 700 && (
            <div className="serivce-stars">
              {rate > 0 ? sstar : sregularStar}
              {rate > 1 ? sstar : sregularStar}
              {rate > 2 ? sstar : sregularStar}
              {rate > 3 ? sstar : sregularStar}
              {rate > 4 ? sstar : sregularStar}
            </div>
          )}
          {width < 700 && (
            <div className="serivce-stars">
              {rate > 0 ? star : regularStar}
              {rate > 1 ? star : regularStar}
              {rate > 2 ? star : regularStar}
              {rate > 3 ? star : regularStar}
              {rate > 4 ? star : regularStar}
            </div>
          )} */}
          <p className="serivce-test-text">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default SingleServiceTestimony;
