// import Navbar from "./components/Navbar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./scenes/Home";
import Services from "./scenes/Services"; 
import LandingRoutes from "./scenes/LandingRoutes";
import Chatbox from "./components/bot/chatbox";
import ReactGA from "react-ga4";
import React, { useEffect } from "react"; 
import About from "./scenes/About";
import CoursePage from "./scenes/CoursePage";
import ServicePage from "./scenes/ServicePage";
import Programs from "./scenes/Programs";
import Treatments from "./scenes/Treatments";
import Libraries from "./scenes/Libraries";
import Cert from "./scenes/Cert";
import ServiceAbout from "./scenes/ServiceAbout";
import LibrariesService from "./scenes/LibrariesService";
import OurTreatments from "./scenes/OurTreatments.";
import BlogPage from "./scenes/BlogPage";
import ServiceBlogPage from "./scenes/ServiceBlog";
const TRACKING_ID = "G-62JLG2XSVD";
function App() {
  const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    // alert('Complete Cache Cleared')
};
  useEffect(() => {
    ReactGA.initialize("G-62JLG2XSVD");
    ReactGA.send({
      hitType: "pageview",
      page: "/home",
    });
    window.addEventListener('beforeunload', handleOnbeforeunload);
    // clearCacheData();
  }, []);
  const handleOnbeforeunload = () => {
    localStorage.clear();
  };
  return (
    <>
      <Router> 
        <Routes>
          <Route path="/training" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/cert" element={<Cert />} />
          <Route path="/program" element={<CoursePage />} />
          <Route path="/treatments" element={<Treatments />} />
          <Route path="/our-treatments" element={<OurTreatments />} />
          <Route path="/treatment" element={<ServicePage />} />
          <Route path="/a-treatment" element={<ServicePage />} />
          <Route path="/about" element={<About/>} />
          <Route path="/about-us" element={<ServiceAbout/>} />
          <Route path="/library" element={<Libraries />} />
          <Route path="/libraries" element={<LibrariesService />} />
          <Route path="/blog" element={<BlogPage />} /> 
          <Route path="/a-blog" element={<BlogPage />} /> 
          <Route path="/" element={<LandingRoutes />} />
        </Routes>
     

      </Router>
      <Chatbox  /> 
    </>
  );
}

export default App;
