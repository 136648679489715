import React from "react";
import logo from "./assets/big-logo.png";
import Button from "./Button";
import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import tik from "./assets/tik.png";
import youtube from "./assets/youtube.png";
import linkedin from "./assets/linkedin.png";
import { Link } from "react-router-dom";
import CustomizedDialogs from "./MuiDialog";
import BookDialogs from "./BookDialog";
import { ReactComponent as TikTok } from "./assets/tiktok.svg";

function DesktopFooter({ action, address }) {
  return (
    <div className="desktop-footer">
      <div className="first-row">
        <div className="big-footer-text">
        {action == "REGISTRATION" ? <h3>Get Started Today!</h3>:
        <h3>Book Your Skincare Treatment Today!</h3>
        }
        </div>
        <div className="buttons-footer">
          {action == "REGISTRATION" ? (
            <CustomizedDialogs small={false} />
          ) : (
            <BookDialogs small={false} />
          )}
          
          <a href="tel:+251911615776">
          <Button contactButton={true}
            className="btn desktop-button"
            children="Contact Us"
            buttonColor="btn--white"
            buttonSize="btn--big"
          /></a>
        </div>
      </div>

      <hr className="solidsdk" />

      <div className="second-row">
        <div className="address">
          <div className="logo-and-name">
            <Link to="/" className="link no_highlights">
              <img src={logo} alt="logo" className="serum-logo-desk" />
            </Link>
            <h3>Serum Skin Care</h3>
          </div>
          <p>
          { 
         'TEACHING THE SCIENCE OF BEAUTIFUL SKIN' 
          }
          </p>
        </div>
        <div className="contact-us-section">
          <h3>Contact Us</h3>
          <p>
            info@serumet.com <br />
            <a href="tel:+251911615776">  09 11 61 57 76 </a> |   <a href="tel:+251927989999">09 27 98 99 99</a><br />
            <a href="https://goo.gl/maps/CPJ72ieirbBaQq5u7" target="_blank"> Bole Medhanialem, Alemnesh Plaza Building</a>
          </p>
        </div>
      </div>
      <hr className="solidsdk" />
      <div className="third-row">
        <p>© 2023 Serum. All rights Reserved.</p><p
          className="andessa-it"
        
        > <a
        href="https://anbessait.com/"
        target="_blank"
      >Designed & Developed by </a>
        <a
          href="https://anbessait.com/"
          target="_blank"
          className="anbessaitspan"
        >
          Anbessa IT
        </a></p>
        <div className="social-media-footer">
        <a
              href="https://facebook.com/serumskincareet"
              target="_blank"
              className="no_highlights"
            >
            <img src={facebook} alt="facebook" />  </a>
            <a
              href="https://instagram.com/serumskincareet?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              className="no_highlights"

            >
              <img src={instagram} alt="instagram" />
            </a>
          {/* <TikTok className="tiktokIcon"/> */}
          <a
              href="https://www.tiktok.com/@serumskincareet"
              target="_blank"
              className="no_highlights"

            >
          <img src={tik} alt="tiktok" /></a>
          {/* <img src={linkedin} alt="linkedin" />
          <img src={youtube} alt="youtube" /> */}
        </div>
      </div>
    </div>
  );
}

export default DesktopFooter;
