import React from 'react'
import "./Button.css"
import {Link} from 'react-router-dom'

function Button({children,  buttonSize, buttonColor, address, contactButton}) {
    const  onClickHandler =  ()=>{}
  return (<>
    {contactButton?<button className={`btn ${buttonColor} ${buttonSize} no_highlights`} onClick={onClickHandler}>
            {children}
        </button>:<Link to={address} className='btn-mobile'>
        <button className={`btn ${buttonColor} ${buttonSize}`} onClick={onClickHandler}>
            {children}
        </button>
    </Link>}
    </>
  )
}

export default Button