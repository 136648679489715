import React, { useEffect, useState, useRef } from "react";
import ServiceCard from "../components/ServiceCard";
import Testimonials from "../components/Testimonials";
import CTA from "../components/CTA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./Home.css";
import "../components/Desktop.css";
import DesktopFooter from "../components/DesktopFooter";
import GallerySection from "../components/GallerySection";
import CaraousalHero from "../components/CaraousalHero";
import useWindowDimensions from "../functions/useWindow";
import axios, { newAbortSignal } from "../api/axios";
import ReactGA from "react-ga";
import VideoSection from "../components/VideoSection";
import Button from "../components/Button";
import { Link, useLocation } from "react-router-dom";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import NavElementService from "../components/NavBar/NavElementService";
import GoToTop from "../functions/GotToTop";
import { getLastWord, removeLastWord } from "../functions/HeaderTitleStyle";
import MobileCarousalHero from "../components/MobileCarousalHero";
import * as constants from "../utils/constants.js";
import ServiceTestimonySlider from "../components/beforeAfter/ServiceTestimonySlider";
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";

function Service() {
  const [headerLoaded, setHeaderLoaded] = useState(false);
  const [serviceLoaded, setServiceLoaded] = useState(false);
  const [testimonyLoaded, setTestimonyLoaded] = useState(false);

  const location = useLocation();

  const arrow = <FontAwesomeIcon icon={faArrowRight} />;
  const ref = useRef();
  const { width } = useWindowDimensions();
  useEffect(() => {
    ReactGA.initialize("G-62JLG2XSVD");
    ReactGA.pageview(location.pathname);
  }, []);

  const [visibelText, setVisibelText] = useState();
  useEffect(() => {
    if (width < 700) {
      setTimeout(() => {
        setVisibelText(true);
      }, 500);
    }
  }, []);

  /// CHNGED WIDTH
  useEffect(() => {
    if (width >= 700) {
      if (
        localStorage.getItem("service-header") !== null &&
        serviceCareHeader == []
      ) {
        var retrievedObject = localStorage.getItem("service-header"); 
        setServiceCareHeader(JSON.parse(retrievedObject));
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("service-header") === null &&
        width >= 700
      ) {
        const fetchData = async () => {
          await getLandingPage().then((response) => { 
        console.log(response.filter((e) =>  e.type === "service_desktop_header")[0].data.list, "LLLLLLLLLLLLLLLLLLLLLLLLLLL")

            setServiceCareHeader(response.filter((e) =>  e.type === "service_desktop_header")[0].data.list);
             setHeaderLoaded(true); 

          }) 


      
        }
        fetchData();
      }
    } else {
      if (
        localStorage.getItem("service-mobile-header") !== null &&
        mobileHeader == []
      ) {
        var retrievedObject = localStorage.getItem("service-mobile-header");
        setMobileHeader(JSON.parse(retrievedObject));
        setMobileHeaderLoaded(true);
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("service-mobile-header") === null &&
        width < 700
      ) {
        const fetchData = async () => {
          await getLandingPage().then((response) => { 

            setMobileHeader(response.filter((e) => e.type === "service_mobile_header")[0].data.list);
            setHeaderLoaded(true);
            
          })
        }
        fetchData();
      }
    }
  }, [width]);

  // Get Service Care Header
  const [serviceCareHeader, setServiceCareHeader] = useState([]);
  const [mobileHeader, setMobileHeader] = useState([]);
  const [mobileHeaderLoaded, setMobileHeaderLoaded] = useState(false);
  const SERVICECAREHEADER_URL = "/api/skin-care-headers?populate=*";
  const MOBILE_HERO_URL = "/api/service-mobile-headers?populate=*";
 
  useEffect(() => {
    try {
      //DESKTOP
      if (localStorage.getItem("service-header") !== null && width >= 700) {
        var retrievedObject = localStorage.getItem("service-header");
        console.log(JSON.parse(retrievedObject))

        setServiceCareHeader(JSON.parse(retrievedObject));
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("service-header") === null &&
        width >= 700
      ) { 
        const fetchData = async () => {
          await getLandingPage().then((response) => { 
        console.log(response.filter((e) =>  e.type === "service_desktop_header")[0].data.list, "LLLLLLLLLLLLLLLLLLLLLLLLLLL")

            setServiceCareHeader(response.filter((e) =>  e.type === "service_desktop_header")[0].data.list);
            setHeaderLoaded(true); 

          })
        }
        fetchData();
      } // PHONE
      else if (
        localStorage.getItem("service-mobile-header") !== null &&
        width < 700
      ) {
        var retrievedObject = localStorage.getItem("service-mobile-header");
        setMobileHeader(JSON.parse(retrievedObject)); 
        setMobileHeaderLoaded(true);
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("service-mobile-header") === null &&
        width < 700
      ) {
        const fetchData = async () => {
          await getLandingPage().then((response) => { 

            setMobileHeader(response.filter((e) => e.type === "service_mobile_header")[0].data.list);
            setHeaderLoaded(true);
            
          })
        }
        fetchData();
      }
    } catch (e) {
      console.log("EEEEEEEEEEEEEERRRRRRRRRRRRRRRRRRRRR")
      setHeaderLoaded(true);
    }
  }, []);

  /// GET TREATMENTS
  const [services, setServices] = useState([]);
  const TREATMENTS_URL = "/api/treatments?populate=*";
  useEffect(() => {
    if (headerLoaded) {
      try {
        if (localStorage.getItem("treatments") !== null) {
          var retrievedObject = localStorage.getItem("treatments");
          console.log(JSON.parse(retrievedObject))
  
          setServices(JSON.parse(retrievedObject));
          setServiceLoaded(true);
        } else if (
          localStorage.getItem("treatments") === null
        ) { 
          const fetchData = async () => {
            await getLandingPage().then((response) => { 
          // console.log(response.filter((e) =>  e.type === "services")[0].data.list, "LLLLLLLLLLLLLLLLLLLLLLLLLLL")
  
          setServices(response.filter((e) =>  e.type === "services")[0].data.list);
              setServiceLoaded(true); 
  
            })
          }
          fetchData();
        }
        // const response = async () => {
        //   await axios
        //     .get(TREATMENTS_URL, {
        //       signal: newAbortSignal(constants.TIMEOUT_LONG),
        //     })
        //     .then((response) => {
        //       setServiceLoaded(true);
        //       setServices(response.data.data);
        //     })
        //     .catch((e) => {
        //       setServiceLoaded(true);
        //       console.log(e);
        //     });
        // };
        // response();
      } catch (e) {
        setServiceLoaded(true);
      }
    }
  }, [headerLoaded]);

  /// GET RESULT
  const [testimonies, setTestimonies] = useState([]);
  const TESTIMONY_URL = "/api/results?populate=*";
  useEffect(() => {
    if (headerLoaded) {
      try {
        if (localStorage.getItem("result") !== null) {
          var retrievedObject = localStorage.getItem("result");
          // console.log(JSON.parse(retrievedObject))
  
          setTestimonies(JSON.parse(retrievedObject));
          setTestimonyLoaded(true);
        } else if (
          localStorage.getItem("result") === null
        ) { 
          const fetchData = async () => {
            await getLandingPage().then((response) => { 
          // console.log(response.filter((e) =>  e.type === "result")[0].data.list, "LLLLLLLLLLLLLLLLLLLLLLLLLLL")
  
          setTestimonies(response.filter((e) =>  e.type === "result")[0].data.list);
          setTestimonyLoaded(true); 
  
            })
          }
          fetchData();
        }
        // const response = async () => {
        //   await axios
        //     .get(TESTIMONY_URL, {
        //       signal: newAbortSignal(constants.TIMEOUT_LONG),
        //     })
        //     .then((response) => {
        //       setTestimonies(response.data.data);
        //       setTestimonyLoaded(true);
        //     })
        //     .catch((e) => {
        //       setTestimonyLoaded(true);
        //       console.log(e);
        //     });
        // };
        // response();
      } catch (e) {
        setTestimonyLoaded(true);
      }
    }
  }, [headerLoaded]);
  // PRELOADER
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(true);

  const handelVideoSectionIsLoaded = () => {
    setTimeout(() => {
      setIsWidgetLoaded(true);
    }, 2000);
  };
  return (
    <>
      <NavElementService />
      {width >= 700 ? (
        <CaraousalHero header={serviceCareHeader} />
      ) : (
        <MobileCarousalHero header={mobileHeader} />
      )}
      {width>=700?
        <div className={"elfwidget"}>
          <div className="video-container">
            <VideoSection
              isLoadedFunction={() => handelVideoSectionIsLoaded()}
            />

            {isWidgetLoaded && (
              <div className="overlay">
                                             <h2>TEACHING THE SCIENCE OF  <span>BEAUTIFUL SKIN</span></h2>


                {/* <p>DESCRIPTION</p> */}
                <div className="video-buttons">
                  <a href="tel:+251911615776">
                    <Button
                      contactButton={true}
                      className="btn desktop-button"
                      children="Contact Us"
                      buttonColor="btn--dark"
                      buttonSize="btn--big"
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        :
        <>
        <div className="header-gap-not-smaller" />

        <VideoSection isLoadedFunction={() => handelVideoSectionIsLoaded()} />
      </>
      }
      <div className="global-component-padding">
        <div className="sectionTitle">
          <h2>Services</h2>
        </div>

        <div className="services-card-desktop">
          <div className="hugger">
            {services.slice(0, 6).map((e) => {
              return (
                <ServiceCard
                  // image={e.attributes.image.data.attributes.formats.large.url}
                  image={e.image.url}

                  title={e.title[0].text}
                  detail={e.short_description[0].text}
                  id={services.indexOf(e)}
                  
                  background=" #166473" 
                  service = {true}
                />
              );
            })}
          </div>
        </div>

        <div className="explore-more">
          <Link to={"/treatments"} className="link">
            <h4 className="cardLink center">Explore more services</h4>
          </Link>
          {arrow}
        </div>
      </div>

      {width < 700 && <br />}
      {width >= 1000 ? (
        <div className="global-component-padding elfwidget">
          <div className="sectionTitle">
            <h2>Follow us on Instagram</h2>
          </div>
          <GallerySection />
        </div>
      ) : null}

      {testimonyLoaded && testimonies.length && (
        <div className="global-component-padding">
          
          <ServiceTestimonySlider testimonies={testimonies}/>
        </div>
      )}
      {/* <h1 className="center">results under work</h1> */}

      <CTA buttonType={2} />
      {width >= 1000 ? (
        <DesktopFooter address={"/book"} action={"BOOK NOW"} />
      ) : (
        <Footer />
      )}

      <GoToTop />
    </>
  );
}

export default Service;
