import React from "react";
import "./DesktopNavbar.css";
import logo from "./assets/big-white-log.png";
import { Link } from "react-router-dom";
import tik from "./assets/tik.png";
import facebook from "../scenes/assets/facebook.png";
import instagram from "../scenes/assets/instagram.png";
import twitter from "../scenes/assets/twitter.png";
import youtube from "../scenes/assets/youtube.png";
import linkedin from "../scenes/assets/linkedin.png";
import { ReactComponent as Ti } from "./assets/tiktok.svg";

function DesktopNavbar({
  serviceTitle,
  trainingTitle,
  serviceImage,
  trainingImage,
}) {
  return (
    <div className="DNB">
      <div className="LB c">
        <div className="LBC">
          <img src={logo} alt="logo" className="LB-LOGO" />
        </div>
      </div>
      <div className="SS c">
        <Link to="/services" className="link mainOne hvr">
          <div className="mainText">
            <h2>{serviceTitle}</h2>
          </div>

          <div
            className="backImage"
            style={{
              background: `url(${serviceImage}), lightgray 50% / contain no-repeat`,
            }}
          ></div>
        </Link>
      </div>
      <div className="vertical-rode" />
      <div className="TS c">
        <Link to="/training" className="link mainTwo hvr">
          <div className="mainText">
            <h2>{trainingTitle}</h2>
          </div>

          <div
            className="backImage"
            style={{
              background: `url(${trainingImage}), lightgray 50% / contain no-repeat`,
            }}
          ></div>
        </Link>
      </div>
      <div className="BB c">
        <div className="BBC row">
          <p>info@serum.com</p>
          {/* <p>09 11 22 33 44 55 | 09 11 22 33 44 55</p> */}
          <p>09 11 61 57 76 | 09 27 98 99 99</p>
          <div className="icons" >
          <a
              href="https://facebook.com/serumskincareet"
              target="_blank"
            >
            <img src={facebook} alt="facebook" />  </a>
            <a
              href="https://instagram.com/serumskincareet?igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
            
              href="https://www.tiktok.com/@serumskincareet"
              target="_blank"
            > <img src={tik} className="tiktokIconH makewhite" alt="tiktok"  />
            </a> 
            <img src={linkedin} alt="linkedin" className="hidden-things" />
            <img src={youtube} alt="youtube" className="hidden-things" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopNavbar;
