import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularfaStar } from "@fortawesome/free-regular-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../functions/useWindow";

function SingleTestimony({ name, rate, text, photo }) {
  const star = <FontAwesomeIcon icon={faStar} />;
  const regularStar = <FontAwesomeIcon icon={regularfaStar} />;
  const sstar = <FontAwesomeIcon icon={faStar} size="xs" />;
  const sregularStar = <FontAwesomeIcon icon={regularfaStar} size="xs" />;
  const quote = <FontAwesomeIcon icon={faQuoteRight} />;
  const { height, width } = useWindowDimensions();

  return (
    <div className="single-testimony">
      <div className="testimony-avatar">
        <img src={photo} alt="avatar" />
      </div>
      <div className="testimony-text">
        <div className="testifier">{width >= 700 ? quote : null}</div>
        <div className="testifier">
          {width < 700 ? quote : null}
          <h5>{name}</h5>
        </div>
        {width >= 700 && (
          <div className="stars">
            {rate > 0 ? sstar : sregularStar}
            {rate > 1 ? sstar : sregularStar}
            {rate > 2 ? sstar : sregularStar}
            {rate > 3 ? sstar : sregularStar}
            {rate > 4 ? sstar : sregularStar}
          </div>
        )}
        {width < 700 && (
          <div className="stars">
            {rate > 0 ? star : regularStar}
            {rate > 1 ? star : regularStar}
            {rate > 2 ? star : regularStar}
            {rate > 3 ? star : regularStar}
            {rate > 4 ? star : regularStar}
          </div>
        )}
        <p className="test-text">{text}</p>
      </div>
    </div>
  );
}

export default SingleTestimony;
