import React from 'react'
import ServiceNavBar from './ServiceNavBar'

function NavElementTraining() {
  return (
    <ServiceNavBar
    first={{ name: "Home", address: "/training" }}
    second={{ name: "About Us", address: "/about" }}
    third={{ name: "Programs", address: "/programs" }}
    fourth={{ name: "Library", address: "/library" }}
    fifth={{ name: "Treatments", address: "/our-treatments" }}
    sixth={{ name: "Certification", address: "/cert" }}
    button={{ name: "REGISTRATION", address: "/register" }}
  />

  )
}

export default NavElementTraining