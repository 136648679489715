import React, { useRef, useEffect, useState } from "react";

import { ReactComponent as TikTok } from "../components/assets/tiktok.svg";
import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import logo from "../components/assets/big-white-log.png";
import "./NewMobileLanding.css";
import { Link } from "react-router-dom";
import tik from "../components/assets/tik.png";

function NewMobileLanding({
  serviceTitle,
  trainingTitle,
  serviceImage,
  trainingImage,
}) {
  const [clearService, setClearService] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setClearService(false);
      serviceTime1();
    }, 2000);
  }, []);
  const serviceTime = () => {
    setTimeout(() => {
      setClearService(false);
      serviceTime1();
    }, 2000);
  };
  const serviceTime1 = () => {
    setTimeout(() => {
      setClearService(true);
      serviceTime();
    }, 2000);
  };

  return (
    <div className="NewMobileLanding">
      <div className="nml-grid">
        <div className="nml-header">
          <div className="nml-logo">
            <img src={logo} alt="logo" className="serum-logos" />
          </div>

          <div className="nml-contacts">
            <p>
            09 11 61 57 76 | 09 27 98 99 99
              <br />
              info@serum.com
            </p>
          </div>
        </div>

        {/* <div className="nml-service"> */}
        <Link to="/services" className="link centerFills newcenterml nml-link">
        <div className="nml-text-parent"><h2 className="nml-text">{serviceTitle}</h2>
          </div>

          <div
            className={
              clearService ? "sshadow moving-shadow" : "nmlshadow nml-none"
            }
          />
          <img
            className={clearService ? " moving-shadow" : "nml-none"}
            src={serviceImage}
            alt="image"
          />
        </Link>
        {/* </div> */}

        <div className="whiteSpacemiddle" />

        {/* <div className="nml-training nml-link"> */}
        <Link to="/training" className="link centerFills newcenterml nml-link">
          <div className="nml-text-parent">
            <h2 className="nml-text">{trainingTitle}</h2>
          </div>
          <div
            className={
              !clearService ? "sshadow moving-shadow" : "nmlshadow nml-none"
            }
          />
          <img
            className={!clearService ? " moving-shadow" : "nml-none"}
            src={trainingImage}
            alt="image"
          />
        </Link>
        {/* </div> */}

        <div className="nml-footer">
          <a href="https://facebook.com/serumskincareet" target="_blank">
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://instagram.com/serumskincareet?igshid=MzRlODBiNWFlZA=="
            target="_blank"
          >
            <img src={instagram} alt="instagram"  />
          </a>
          <a href="https://www.tiktok.com/@serumskincareet" target="_blank">
            {/* <TikTok className="tiktokIconH" />{" "} */}
             <img src={tik} className="tiktokIconH makewhite" alt="tiktok" />
          </a>
          {/* <img src={tik} className="tiktokIconH" alt="tiktok" /> */}
        </div>
      </div>
    </div>
  );
}

export default NewMobileLanding;
