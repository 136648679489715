import React from "react";
import logo from "./assets/big-logo.png";
import Button from "./Button";
import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import tik from "./assets/tik.png";
import youtube from "./assets/youtube.png";
import linkedin from "./assets/linkedin.png";
import "../components/Desktop.css";
import { Link } from "react-router-dom";
import { ReactComponent as TikTok } from "./assets/tiktok.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="logo-contact row">
        {/* <Link to="/" className="link">
          <img src={logo} alt="logo" className="serum-logo" />
        </Link> */}
        <Link to="/" className="link no_highlights">
          <img src={logo} alt="logo" className="serum-logo" />
        </Link>
        {/* <Button
          className="btn"
          children="Contact Us"
          buttonColor="btn--dark"
          buttonSize="btn--big"
        /> */}
      </div>
      <p>TEACHING THE SCIENCE OF BEAUTIFUL SKIN</p>
      <br />
      <h3>Contact Us</h3>
      <p>
        <span>info@serumet.com</span> <br />
        <br />
        <a href="tel:+251911615776" className="underlined-link">
          {" "}
          09 11 61 57 76{" "}
        </a>{" "}
        |{" "}
        <a href="tel:+251927989999" className="underlined-link">
          09 27 98 99 99
        </a>{" "}
        <br />
        <br />
        <a
          href="https://goo.gl/maps/CPJ72ieirbBaQq5u7"
          target="_blank"
          className="underlined-link"
        >
          {" "}
          Bole Medhanialem, Alemnesh Plaza Building
        </a>
      </p>
      <hr className="solid" />
      <div className="social-media row">
        <a
          href="https://facebook.com/serumskincareet"
          target="_blank"
          className="no_highlights"
        >
          <img src={facebook} alt="facebook" />{" "}
        </a>
        <a
          href="https://instagram.com/serumskincareet?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          className="no_highlights"
        >
          <img src={instagram} alt="instagram" />
        </a>
        {/* <TikTok className="tiktokIcon"/> */}
        <a
          href="https://www.tiktok.com/@serumskincareet"
          target="_blank"
          className="no_highlights"
        >
          <img src={tik} alt="tiktok" />
        </a>
        {/* <img src={twitter} alt="twitter" /> */}
        {/* <img src={linkedin} alt="linkedin" />
        <img src={youtube} alt="youtube" /> */}
      </div>
      <p 
          >
        © 2023 Serum. All rights Reserved.</p><p
          className="andessa-it"
        
        ><a
        href="https://anbessait.com/"
        target="_blank"
      > Designed & Developed by </a>
        <a
          href="https://anbessait.com"
          target="_blank"
          className="anbessaitspan"

        >
          Anbessa IT
        </a>{" "}
      </p>
    </div>
  );
}

export default Footer;
