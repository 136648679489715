import React, { useEffect, useState } from "react";
import "./Chatbox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { ElfsightWidget } from "react-elfsight-widget";
// import { useLocation } from "react-router-dom";
function Chatbox({ closefuntion }) {
  const closeBar = <FontAwesomeIcon icon={faX} size="xs" />;
  const [home, setHome] = useState(false);

  // const location = useLocation();
  // return (
  //   <div className="chat-box-container">
  //     <div className="chat-box">
  //       <div className="chat-box-header">
  //         <div className="avatar-container">
  //           <img className="avatar" src={handface}></img>
  //         </div>
  //         <div className="name-title">
  //           <p className="name">Sara Mekonen</p>
  //           <p className="title">Public relation</p>
  //         </div>

  //         <div className="close-icon-button" onClick={closefuntion}>
  //           {closeBar}
  //         </div>
  //       </div>
  //       <div className="chat-box-body">
  //         <div className="chat-time">12:00</div>
  //         <div className="chat-bar row">
  //           <div className="chat-avatar">
  //             <img className="avatar" src={handface}></img>
  //           </div>
  //           <div className="chat-message">
  //             <p>
  //               {" "}
  //               Hi! <br />
  //               Ask me anything, I am here to help.
  //             </p>
  //           </div>
  //         </div>
  //         <div className="cta">
  //           <div className="cta-text"> Start Chat with:</div>
  //           <a
  //             className="cta-btn"
  //             aria-label="Chat on WhatsApp"
  //             href=" https://wa.me/251911615776"
  //             target="_blank"
  //           >
  //             {<Telegram className="telegram-icon" />}
  //             {"Telegram"}
  //           </a>
  //           <p className="or"> - or - </p>

  //           <a
  //             className="cta-btn"
  //             aria-label="Chat on WhatsApp"
  //             href=" https://wa.me/251911615776"
  //             target="_blank"
  //           >
  //             {<Whatsapp className="whatsapp-icon" />}
  //             {"Whatsapp"}
  //           </a>
  //           <p className="or"> - or - </p>
  //           <a
  //             className="cta-btn"
  //             aria-label="Chat on WhatsApp"
  //             href=" https://wa.me/251911615776"
  //             target="_blank"
  //           >
  //             {
  //               <Instagram className="instagram-icon">
  //                 <linearGradient id="gradient">
  //                   <stop offset="5%" stop-color="#FFC338" />
  //                   <stop offset="95%" stop-color="#FFEA68" />
  //                 </linearGradient>
  //               </Instagram>
  //             }
  //             {"Instagram"}
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <>
      <ElfsightWidget
        style={{
          overflow: "hidden",
          position: "absolute ",
          right: "0",
          padding: "0 !important",
          // zIndex:"9999"
        }}
        widgetId={"8c25186d-cc3a-4df4-8eba-ce58a0d11e44"}
      />
    </>
  );
}

export default Chatbox;
