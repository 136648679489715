import React from 'react'
import "./LibraryCard.css"
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";

function LibraryCard({image, title, id}) {
  const location = useLocation();

  return (
    <div className='library-card'>
        <div className='lc-image'>
            <img src={image} alt='Image'/>
        </div>
        {location.pathname === "/library"?<div className='lc-text'>
            <Link to={`/a-blog?id=${id}`} className='link'> <h3>{title}</h3></Link>
            <h5>July 29, 2023</h5>
        </div>:
        <div className='lc-text'>
            <Link to={`/blog?id=${id}`} className='link'> <h3>{title}</h3></Link>
            <h5>July 29, 2023</h5>
        </div>}
    </div>
  )
}

export default LibraryCard