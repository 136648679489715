import React from "react";
import Button from "./Button";
import CustomizedDialogs from "./MuiDialog";
import BookDialogs from "./BookDialog";

function CTA({ buttonType }) {
  return (
    <>
      {/* <hr className="solid-dark-alone" /> */}

      <div className="CTA">
        <h2>What is Next?</h2>
        <ul>
          <li>Book your consultation.</li>
          <li>Receive a tailored treatment plan.</li>
          <li>Together, we'll put your plan into action.</li>
          <li>Experience and enjoy healthy skin.</li>
          <br />
        </ul>
        {buttonType == 1 ? (
         <CustomizedDialogs white={true} />
        ) : buttonType == 2 ? (
         <BookDialogs white={true}/> 
        ) : (
          <a href="tel:+251911615776" className="no_highlights">
          <Button contactButton={true}
            className="btn desktop-button"
            children="Contact Us"
            buttonColor="btn--white"
            buttonSize="btn--big"
          /></a>
        )}
      </div>
      {/* <hr className="solid-dark-alone" /> */}
    </>
  );
}

export default CTA;
