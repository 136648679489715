import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import useWindowDimensions from "../functions/useWindow";
// import plc from "../scenes/assets/placeholder-image.png" 
import plc from "../scenes/assets/splc.png" 
// import 'bootstrap/dist/css/bootstrap.min.css';
function VideoSection({ isLoadedFunction }) {
  const widgetRef = useRef(null);
  // const [isLoaded, setIsLoaded] = useState(false);

  //   useEffect(() => {

  //      setTimeout(()=>{
  //     const instance = widgetRef.current.getInstance();

  //      },3000)

  //   }, []);
  // // const widget = ElfsightWidget.create("fd6af8ef-0202-49a3-850e-97cf7e11617c");
  const { width } = useWindowDimensions();
  // const divRef = useRef(null);
  // const [size, setSize] = useState({ width: 0, height: 0 });

  // useLayoutEffect(() => {
  //   const div = divRef.current;

  //   function handleResize(entries) {
  //     const { width, height } = entries[0].contentRect;
  //     setSize({ width, height });
  //     if(height>100){
  //       isLoadedFunction()
  //     }
  //   }

  //   const resizeObserver = new ResizeObserver(handleResize);
  //   resizeObserver.observe(div);

  //   return () => {
  //     resizeObserver.unobserve(div);
  //   };
  // }, []);

  //   useEffect(()=>{
  //     isLoadedFunction()
  //   },[size])
  return (
    <>
      {width >= 700 ? (
        <div>
          <ElfsightWidget
            widgetId={"fd6af8ef-0202-49a3-850e-97cf7e11617c"}
            ref={widgetRef}
            onRender={isLoadedFunction}
            // style={{ overflow:"hidden !important"}}
            // className="elfsight-app"
          />
        </div>
      ) : 
       
      
      
      <div style={{
        // border:"2px solid red",
        overflow:"hidden",
      // paddingBottom:"100%",
        position:"relative",
        height:"678px", 
        display:'flex',
        justifyContent:"center",
        zIndex:"-1 !important",
        backgroundImage:`url(${plc})`,
        backgroundPosition:"center",
        
        // width:"100%",
        }}>
          <div  style={{
          left:"-10px", //0
          top:"-50px", //0
          zIndex:"0",
            // background:" rgba(26, 122, 66, 0.5)",
          position: "relative", 

        

        }}>
          {/* <iframe width="560" height="315" src="https://killerplayer.com/watch/video/1a02421c-4065-422b-b671-af62b35a159e" title="YouTube video player" frameborder="0"   ></iframe> */}
      <iframe
        
          style={{
            position: "relative", 
            zIndex:"-99999",
        display:'block',
        border: "0px"

          }}
        width={"1400"}
        height={"780"} //750
        // src="https://killerplayer.com/watch/video/1a02421c-4065-422b-b671-af62b35a159e"
        src="https://killerplayer.com/watch/video/61bbfdc1-958e-48bf-bd81-2061f45257b4"
   
        allow="gyroscope; picture-in-picture;"
        title="video"
      />
      {/* <Iframe 
      url="https://killerplayer.com/watch/video/1a02421c-4065-422b-b671-af62b35a159e"
      src="https://killerplayer.com/watch/video/1a02421c-4065-422b-b671-af62b35a159e"
        width="1400px"
        height="750px" 
        position= "relative"
            zIndex="99999"
        display='block'
        border= "0px" 
        loading ="true"
        /> */}
     </div>
     </div>
      // (
      //   <div
      //     style={{
      //       position: "relative",
      //       width: "100%",
      //       height: "567px",
      //       overflow: "hidden",
      //     }}
      //   >
      //      <ElfsightWidget
      //       widgetId={"518040d2-956e-4092-af82-4b516711a996"}
      //       ref={widgetRef}
      //       autoplay={false}
      //       rel="0"
      //       // onRender={isLoadedFunction}
      //       style={{ overflow: "hidden !important" }}
      //       // className="elfsight-app"
      //     />  
      //   </div>
      // )
      }
    </>
  );
}

export default VideoSection;

const iframe = <iframe style='display:block;margin:auto;width:742px;max-width:100%;aspect-ratio:1.7708830548926013;' src='https://killerplayer.com/watch/video/e3f4db69-51aa-4398-b5bb-42581d014c78' frameborder="0" allow="autoplay; gyroscope; picture-in-picture;" allowfullscreen></iframe>
{
  /* <iframe style='display:block;margin:auto;width:742px;max-width:100%;aspect-ratio:1.7708830548926013;' src='https://killerplayer.com/watch/video/e3f4db69-51aa-4398-b5bb-42581d014c78' frameborder="0" allow="autoplay; gyroscope; picture-in-picture;" allowfullscreen></iframe> */
}
 
const Iframes = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  return (
    // basic bootstrap classes. you can change with yours.
    <div className="col-md-12">
      <div className="emdeb-responsive">
        <iframe src={src} style={{display:'block', margin:'auto',height:"1000px", aspectRatio:"0.5899705014749262;"}} frameborder="0" allow="picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  );
};
