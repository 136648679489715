import React from 'react'
import "./Togler.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircle  } from "@fortawesome/free-solid-svg-icons";
import {   faCircle as regular } from "@fortawesome/free-regular-svg-icons";

function Togler({onBack, onForward, place, current, total, hero}) { 
  const leftArrow = <FontAwesomeIcon icon={faChevronLeft} size="2xs" style={{color: "#166473"}}/>
  const rightArrow = <FontAwesomeIcon icon={faChevronRight} size="2xs" style={{color: "#166473"}}/>
  const active = <FontAwesomeIcon icon={faCircle}  size="2xs" style={{color: "#166473"}}/>
  const passive = <FontAwesomeIcon icon={regular}  size="xs" style={{color: "#166473"}}/>
  const dots = [];
  for (let i = 0; i < total; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
  if(i===current)   
  {dots.push( <h1 className='dot1 sh'>
    { active }
 </h1>);
}else{
    dots.push( <h1 className='dot1 sh'>
    {  passive}
 </h1>);}
}
  return (
    <div className='togler row' style={hero && {   justifySelf:'flex-end', 
      alignSelf:' flex-end' ,  
      padding:"5px 10px 0 10px",
      marginRight: '120px',
      borderRadius: '10px 10px 0 0',
      // filter:' drop-shadow(2px 0px 0px #FFFFFF)',
      // background:'rgb(255,255,255,40%)'
      background: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(4.400000095367432px)'
      }}>
        <h1 className='back-toggle sh rightPad no_highlights' onClick={onBack}>
            {leftArrow}
        </h1>
        {/* <h1 className='dot1 sh'>
           {place===1? active: passive}
        </h1>
        <h1 className='dot2 sh'>
        {place===2? active: passive}

        </h1> */}
        {dots}
       
        <h1 className='forward-toggle sh leftPad no_highlights'onClick={ onForward}>
            {rightArrow}
        </h1>
    </div>
  )
}

export default Togler