import React, { useState, useEffect } from "react"; 
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import "./CoursePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Button";
import axios, { newAbortSignal } from "../api/axios";
import BookDialogs from "../components/BookDialog";
import NavElementService from "../components/NavBar/NavElementService";
import GoToTop from "../functions/GotToTop";
import { useLocation } from "react-router-dom";
import * as constants from "../utils/constants.js"
import NavElementTraining from "../components/NavBar/NavElementTraining";
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";

function ServicePage() {
  const { height, width } = useWindowDimensions();
  const check = <FontAwesomeIcon icon={faCheck} size="xs" />;
  const query = new URLSearchParams(useLocation().search);
  const [service, setservice] = useState(null);
  const location = useLocation();

  // const service_URL = `/api/courses/${id}`;
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  useEffect(() => {
    try {
      
      const id = query.get("id"); 
      if (localStorage.getItem("treatments") !== null) {
        var retrievedObject = localStorage.getItem("treatments");
        setservice(JSON.parse(retrievedObject)[id]);
        
      }else{
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setservice(response.filter((e) => e.type === "services")[0].data.list[id]);

          })
        }
        fetchData();
      }
       
    } catch (e) {}
  }, []);

  return (
    <>
    {(location.pathname === "/a-treatment")?  
    <NavElementTraining />
  :  <NavElementService />
  
  }
      <div className="dark-60-bar" />
      {!service ? (
        <div className="loading-page">
          <div>{spinner}</div>
          <h2>Loading . . .</h2>
        </div>
      ) : (
      <div className="course-page">
        {width > 700 && (
          <div className="cp-header">
            <h1>Treatment</h1>
          </div>
        )}
        <div className="border-line">
          <div className="cp-image">
          {service && <img
          //  src={service.image.data.attributes.formats.large.url} 
          src={service.image.url}

           alt="image" />}
          </div>
          <div className="cp-title">
          <h1>{service && service.title[0].text}</h1>
          </div>
          <div className="cp-detail">
          <p>{service && service.long_description[0].text}</p>
          </div>
          <div className="cp-item-list">
            {service.list1.map((e) => {
              if (e !== "")
                return (
                  <div className="single-cp-item">
                    <div className="check-mark">{check}</div>
                    {e.text}
                  </div>
                );
            })}
          </div>
          {/* <Button
            className="btn desktop-button"
            children="BOOK NOW"
            buttonColor="btn--dark"
            buttonSize="btn--big"
            address="/register"
          /> */}
          <BookDialogs />
        </div>
      </div>)}
      <div className="course-page"></div>
      <CTA />
      {width >= 1000 ? (
        <DesktopFooter address={"/book"} action={"BOOK NOW"} />
      ) : (
        <Footer />
      )}
      <GoToTop />
    </>
  );
}

export default ServicePage;
 
