import React, { useState, useEffect } from "react";
import LandingPage from "./LandingPage";
import MobileLanding from "./MobileLanding";
import useWindowDimensions from "../functions/useWindow";
import DesktopNavbar from "../components/DesktopNavbar";
import axios, { newAbortSignal } from "../api/axios";
import logo from "../components/assets/big-logo.png";
import gif from "../components/assets/logo-gif.gif";
import NewMobileLanding from "./NewMobileLanding";
import * as constants from "../utils/constants.js"
import { GetLargeImage, GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getImagesUrls, getLandingPage, getRef } from "../api/prismic";
import { usePreloadImages } from "../functions/preLoad.ts";
function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = img.onabort = function () {
      reject(src)
    }
    img.src = src
    window[src] = img;
  })
}
function LandingRoutes() {
  const [assetsLoaded, setAssetsLoaded] = useState(false)
  const [prismicLoaded, setPrismicLoaded] = useState(false)
  const { height, width } = useWindowDimensions();
  //SET MIN TIME OUT
  const [timeOver, setTimeOver] = useState(false);
  useEffect(() => {
     
    setTimeout(() => {
      setTimeOver(true);
    }, 3000);
  }, []);
  //GET PRISMIC DATA
  const [prismicData, setPrismicData] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      await getLandingPage().then((response) => {
        setPrismicData(response);
        setHomepageLoaded(true);
      }).then(()=>setPrismicLoaded(true))
    }
    fetchData();
    
  }, [])
   
  /// GET HOMEPAGE
  const [homepage, setHomepage] = useState({});
  const [homepageLoaded, setHomepageLoaded] = useState(false);
  return (
    <>
      {(
        width >= 700
          ? !homepageLoaded || !timeOver //|| !assetsLoaded
          : !homepageLoaded || !timeOver
      ) ? (
        <div className="preloader">
          <img src={gif} alt="logo" />

        </div>
      ) : (
        <>
          {" "}
          {width >= 700 ? (
            <DesktopNavbar
              className="desk-land"
              serviceImage={prismicData.filter((e) => e.type === "landing_page")[0].data.service_image.url}
              serviceTitle={prismicData.filter((e) => e.type === "landing_page")[0].data.service_title[0].text}
              trainingImage={prismicData.filter((e) => e.type === "landing_page")[0].data.training_image.url}
              trainingTitle={prismicData.filter((e) => e.type === "landing_page")[0].data.training_title[0].text}

            />
          ) : (
            <NewMobileLanding
              serviceImage={prismicData.filter((e) => e.type === "landing_page")[0].data.service_image.url}
              serviceTitle={prismicData.filter((e) => e.type === "landing_page")[0].data.service_title[0].text}
              trainingImage={prismicData.filter((e) => e.type === "landing_page")[0].data.training_image.url}
              trainingTitle={prismicData.filter((e) => e.type === "landing_page")[0].data.training_title[0].text}
            />

          )}
        </>
      )}
    </>
  );
}

export default LandingRoutes;
