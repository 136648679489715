import React, { useEffect, useState } from "react";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import CardForCourse from "../components/cards/CardForCourse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { newAbortSignal } from "../api/axios";
import "./Programs.css";
import NavElementTraining from "../components/NavBar/NavElementTraining";
import GoToTop from "../functions/GotToTop";
import CustomizedDialogs from "../components/MuiDialog";
import * as constants from "../utils/constants.js"
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";

function Programs() {
  const { height, width } = useWindowDimensions();
  const [courses, setCourses] = useState([]);
  const LOGIN_URL = "/api/programs?populate=*";
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  useEffect(() => {
    try {
      if (localStorage.getItem("courses") !== null) {
        var retrievedObject = localStorage.getItem("courses");
        setCourses(JSON.parse(retrievedObject));
        
      }else{
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setCourses(response.filter((e) => e.type === "trainings")[0].data.training_list);

          })
        }
        fetchData();
      }
      // const response = async () => {
      //   const response = await axios.get(
      //     LOGIN_URL, {
      //       signal: newAbortSignal(constants.TIMEOUT_LONG),
      //     }
      //   );
      //   // console.log(response.data.data);
      //   setCourses(response.data.data);
      // };
      // response();
    } catch (e) {}
  }, []);
  return (
    <>
      <NavElementTraining />

      <div className="dark-60-bar" />
      {/* {width>=700&&<div className="programs-header-image">
        <img
          src="https://res.cloudinary.com/dhe0yelid/image/upload/v1690736365/surgery_e763dbf677.png"
          alt="header-image"
        />
      </div>} */}
      <div className="header-gap" />

      <div className="sectionTitle progTitle">
        <h2>Training Programs </h2>
      </div>
      {!courses.length ? (
        <div className="loading-page">
          <div>{spinner}</div>
          <h2>Loading . . .</h2>
        </div>
      ) : null}
      {courses.length == 0 ? null : (
        <div className="c-s-div">
          <div className="coursesSection">
            {courses.map((e) => {
              return (
                <CardForCourse
                    // image={e.attributes.image.data.attributes.url}
                    // image={GetMediumImage(e.attributes.image.data)}
                    image={e.image.url}

                    title={e.title[0].text}
                    detail={e.short_description[0].text}
                    id={courses.indexOf(e)}
                  />
              );
            })}
          </div>
        </div>
        // </div>
      )}
{width < 700 && <div className="header-gap-not-smaller" />}

      <div className="prog-fit-width">
        <div className="should-i-question">
          <div className="siq-text">
           <div className="siq-sub-texts"><h1 className="siq-title">SHOULD I BECOME AN ESTHETICIAN?</h1> 
            <p className="siq-para">
             A promising career option for someone who is passionate about enhancing people's appearance and wellbeing is becoming an esthetician, also known as a skin care therapist. This profession offers numerous professional opportunities, ranging from working in health and beauty spas to salons, dermatological offices, and more. As an esthetician, you will be able to provide customized treatment options to your clients, including skin evaluations, advice on proper makeup application, peels, masks, scrubs, and massages, among others. Given that the skin is the body's largest and fastest-growing organ, it requires expert care and maintenance to maintain a healthy and beautiful appearance. Therefore, becoming an esthetician can be a gratifying career choice for individuals who enjoy helping others achieve their desired aesthetic goals.
            </p></div>
            <CustomizedDialogs />
          </div>
          <div className="siq-image">
            <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1692980517/should_id_174ab78a7b.jpg" />
          </div>
        </div>
      </div>
      <CTA buttonType={1} />

      {width >= 1000 ? (
        <DesktopFooter address={"/register"} action={"REGISTRATION"} />
      ) : (
        <Footer />
      )}

      <GoToTop />
    </>
  );
}

export default Programs;
