import React from 'react'
import ServiceNavBar from './ServiceNavBar'

function NavElementService() {
  return (
    <ServiceNavBar
    first={{ name: "Home", address: "/services" }}
    second={{ name: "About Us", address: "/about-us" }}
    third={{ name: "Treatments", address: "/treatments" }}
    fourth={{ name: "Library", address: "/libraries" }}
    button={{ name: "BOOK NOW", address: "/book" }}
  />
  )
}

export default NavElementService