import axios from "axios";

export default axios.create({
    baseURL: 'https://'
});
export const prismicAxios = axios.create({
    baseURL: 'https://serumet.prismic.io/api/v2/documents'
});

export function newAbortSignal(timeoutMs) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);
  
    return abortController.signal;
  }