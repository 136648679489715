import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import Togler from "./Togler";
import "./GlobalHero.css";
import "./Testimonials.css";
import SingleTestimony from "./SingleTestimony";
import useWindowDimensions from "../functions/useWindow"; 

function Testimonials({testimonies}) {
  const sliderRef = useRef();
  const [page, setPage] = useState(1);
  const [current, setCurrentPage] = useState(0);
  const [slideNumber, setSlideNumber] = useState();
  const [centerMode, setCenterMode] = useState();
  const { width } = useWindowDimensions();

  var settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    centerMode: centerMode,
    slidesToShow: slideNumber,
    slidesToScroll: 1,
    draggable: true,
    swipeToSlide: true,
    autoplay : true,
    autoplaySpeed: 6000,
    pauseOnHover:false, 
    beforeChange: (current, next) => {
      setCurrentPage(next);
    },
    onSwipe: () => {
      if (page === 2) {
        setPage(1);
      }
      if (page === 1) {
        setPage(2);
      }
    },
  };

  const onNext = () => {
    sliderRef.current.slickNext();
    if (page === 2) {
      setPage(1);
    }
    if (page === 1) {
      setPage(2);
    }
  };
  const onBack = () => {
    sliderRef.current.slickPrev();
    if (page === 2) {
      setPage(1);
    }
    if (page === 1) {
      setPage(2);
    }
  };
  useEffect(() => {
    if (width >= 1200) {
      setSlideNumber(3);
      setCenterMode(false);
    } else if (width > 930) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(true);

      setSlideNumber(1);
    }
  }, [width]);
  useEffect(() => {
    if (width >= 1200) {
      setSlideNumber(3);
      setCenterMode(false);
    } else if (width > 930) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(true);

      setSlideNumber(1);
    }
  }, []);
 

  
  useEffect(() => {
    if (width >= 1200 && testimonies.length>=3) {
      setSlideNumber(3);
      setCenterMode(false);
    } else if (width > 930 && testimonies.length>=2) {
      setCenterMode(false);

      setSlideNumber(2);
    } else {
      setCenterMode(true);

      setSlideNumber(1);
    }
  }, [testimonies]);
  return (
    <>
      {/* {width >=700 
       &&testimonies.length!=0 ? <hr className="solid-dark-alone" />:null} */}
      {/* {!testimonies.length? null : ( */}
        <div className="testimonials">
          <h2 className="center"> Testimonials</h2>
          <div
            className="fit-testamony"
            style={{ width: width < 930 ? "100%" : "90%" }}
          >
            <Slider ref={sliderRef} {...settings}>
              {testimonies.map((e) => {
                 return (
                  <SingleTestimony
                    name={e.name[0].text}
                    text={e.testimony[0].text}
                    photo={e.avatar.url}
                    rate={e.rating}
                    id={e.id}
                  />
                );
              })}
            </Slider>
            <br />
            <br />
          
        <Togler onForward={()=>onNext()} onBack={()=>onBack()} place={page}  current={current} total={testimonies.length?testimonies.length:0} />

            <br />
            <br />
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default Testimonials; 