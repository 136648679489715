import React, { useRef, useEffect, useState } from "react";
import logo from "../components/assets/logo-white.png";
import "./MobileLanding.css";

import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import twitter from "./assets/twitter.png";
import youtube from "./assets/youtube.png";
import linkedin from "./assets/linkedin.png";
import { Link } from "react-router-dom";
import { ReactComponent as TikTok } from "../components/assets/tiktok.svg";

function MobileLanding({
  serviceTitle,
  trainingTitle,
  serviceImage,
  trainingImage,
}) {
  const serviceTab = useRef();
  const trainingTab = useRef();
  const [clearService, setClearService] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setClearService(false);
      serviceTime1();
    }, 2000);
  }, []);
  const serviceTime = () => {
    setTimeout(() => {
      setClearService(false);
      serviceTime1();
    }, 2000);
  };
  const serviceTime1 = () => {
    setTimeout(() => {
      setClearService(true);
      serviceTime();
    }, 2000);
  };

  return (
    <div className="landing-page-views">
      <header className="landing-navs">
        <nav>
          <img src={logo} alt="logo" className="serum-logos" />
          <div className="nav-linkss">
            <p>
              09 11 22 33 44 55 | 09 11 22 33 44 55
              <br />
              info@serum.com
            </p>
          </div>
        </nav>
      </header>
      <div className="center-bodys">
        <Link to="/services" className="link centerFills">
          <div
             
            className={
              clearService ? "clean-service moving-shadow" : "clean-service"
            }
            // style={{
            //   background: `url(${serviceImage}), lightgray 50% / cover no-repeat`,
            // }}
            ref={serviceTab}
          >
            <img src={serviceImage} alt="image"  />
            <h2 className="landing-menu">{serviceTitle}</h2>
          </div>
        </Link>
        <Link to="/training" className="link centerFills">
          <div
             
            className={
              !clearService ? "clean-training moving-shadow" : "clean-training"
            }
            // style={{
            //   background: `url(${trainingImage}), lightgray 50% / contain no-repeat`,
            // }}
            ref={trainingTab}
          >
            <img src={trainingImage} alt="image" />
            <h2 className="landing-menu">{trainingTitle}</h2>
          </div>
        </Link>
      </div>

      <div>
        <div className="social-media-dark row">
          <img src={facebook} alt="facebook" />
          <a
            href="https://instagram.com/serumskincareet?igshid=MzRlODBiNWFlZA=="
            target="_blank"
          >
            <img src={instagram} alt="instagram" />
          </a>

          <TikTok className="tiktokIconH" />
        </div>
      </div>
    </div>
  );
}

export default MobileLanding;
