import React from "react";
// import Home from '../scenes/Home'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

function Menu({ closeMenuFunction, treatment }) {
  const closeBar = <FontAwesomeIcon icon={faX} />;
  // var fixed = document.getElementById('fixed');

  // fixed.addEventListener('scroll', function(e) {
  
  //         e.preventDefault();
  
  // }, false);
  return (
    <>
      {treatment ? (
        <div className="menu" id="fixed">
          <ul>
            <li>
              <Link
                to="/services"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Home
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/about-us"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                About Us
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/treatments"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Treatments
              </Link>
              {/* home */}
            </li>
            <li>
              <Link to="/libraries" className="menuLink">
                Library
              </Link>
              {/* home */}
            </li>
            
          </ul>
          <div className="row closeMenuBar" onClick={() => closeMenuFunction()}>
            <p> {`Close`}  </p> <div style={{paddingLeft:"10px"}}>{closeBar}</div>
          </div>
        </div>
      ) : (
        <div className="menu">
          <ul>
            <li>
              <Link
                to="/training"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Home
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/about"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                About Us
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/programs"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Programs
              </Link>
              {/* home */}
            </li>
            <li>
              <Link to="/library" className="menuLink">
                Library
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/our-treatments"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Treatments
              </Link>
              {/* home */}
            </li>
            <li>
              <Link
                to="/cert"
                className="menuLink"
                onClick={() => closeMenuFunction()}
              >
                Certification
              </Link>
              {/* home */}
            </li>
           
             
          </ul>
          <div className="row closeMenuBar" onClick={() => closeMenuFunction()}>
          <p> {`Close`}  </p> <div style={{paddingLeft:"10px"}}>{closeBar}</div>


          </div>
        </div>
      )}
    </>
  );
}

export default Menu;
