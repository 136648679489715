import React, { useEffect, useState } from "react";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow"; 
import LibraryCard from "../components/LibraryCard";
import "./Libraries.css";
import NavElementTraining from "../components/NavBar/NavElementTraining";
import GoToTop from "../functions/GotToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { newAbortSignal } from "../api/axios";
import NavElementService from "../components/NavBar/NavElementService";
import NewLibraryCard from "../components/NewLibraryCard";
import Slider from "react-slick";
import * as constants from "../utils/constants.js"
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";

function LibrariesService({ training }) {
  const { height, width } = useWindowDimensions();
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  /// GET COURSES
  const [libraries, setLibraries] = useState([]);
  const LIBRARY_URL = "/api/service-libraries?populate=*";
  useEffect(() => {
    try {
      if (localStorage.getItem("service_library") !== null) {
        var retrievedObject = localStorage.getItem("service_library");
        setLibraries(JSON.parse(retrievedObject));
        
      }else{
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setLibraries(response.filter((e) => e.type === "service_library")[0].data.list);

          })
        }
        fetchData();
      }
      // const response = async () => {
      //   await axios
      //     .get(LIBRARY_URL, {
      //       signal: newAbortSignal(constants.TIMEOUT_LONG),
      //     })
      //     .then((response) => {
      //       console.log(response.data);
      //       setLibraries(response.data.data);
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //     });
      // };
      // response();
    } catch (e) {
      console.log(e);
    }
  }, []);
  
  const [slideNumber, setSlideNumber] = useState();

  useEffect(() => {
    if (width >= 1200) {
      setSlideNumber(2);
      //   setCenterMode(false);
    } else if (width > 930) {
      //   setCenterMode(false);

      setSlideNumber(2);
    } else if (width > 700) {
      //   setCenterMode(false);

      setSlideNumber(1);
    } else {
      //   setCenterMode(true);

      setSlideNumber(1);
    }
  }, [width]);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: libraries.length< 2? 1: slideNumber,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: true, 
    // variableWidth:true,
  };
  return (
    <>
      <NavElementService />
      <div className="dark-60-bar" />
      <div className="header-gap" />

      <div className="sectionTitle">
        <h2>Library</h2>
      </div>{" "}
      {!libraries.length ? (
        <div className="loading-page">
          <div>{spinner}</div>
          <h2>Loading . . .</h2>
        </div>
      ) : null}
      <div className="librariesPage">
      <h3 className="libraries-title">Recent Posts</h3>
        {width < 930 && (
          <div className="sliding-library">
            <Slider {...settings}>
              {libraries
                .sort(
                  (a, b) =>
                  Date(a.date) < Date(b.date)

                )
                .slice(0, 2)
                .map((e) => {
                  return (
                    <div className="sliding-lib-card">
                      <NewLibraryCard
                        image={e.image.url}
                        title={e.title[0].text}
                        id={e.title[0].text}
                        article={e.article[0].text}
                        date={e.date}
                      />
                    </div>
                  );
                })}
            </Slider>{" "}
          </div>
        )}
        {width >= 930 && (
          <div className="sliding-librarys rowsl">
            {libraries
              .sort(
                (a, b) =>
                Date(a.date) < Date(b.date)

              )
              .slice(0, 2)
              .map((e) => {
                return (
                  <div className="sliding-lib-card">
                    <NewLibraryCard
                        image={e.image.url}
                        title={e.title[0].text}
                        id={e.title[0].text}

                        article={e.article[0].text}
                        date={e.date}
                      />
                  </div>
                );
              })}
          </div>
        )}
        <h3 className="libraries-title">Archive</h3>
        <h3 className="libraries-title">Our Professionals At Work</h3>

<div className="lflex-card">
  {libraries.filter((e)=>e.category==="Professionals").map((e) => {
    return (
      <NewLibraryCard
                        image={e.image.url}
                        title={e.title[0].text}
                        id={e.title[0].text}

                        article={e.article[0].text}
                        date={e.date}
                      />
    );
  })}
</div>
<br/>
        <h3 className="libraries-title">Transformations</h3>

        <div className="lflex-card">
          {(libraries.filter((e)=>e.category==="Transformation")).map((e) => {
            return (
              <NewLibraryCard
              image={e.image.url}
              title={e.title[0].text}
              id={e.title[0].text}

              article={e.article[0].text}
              date={e.date}
            />
            );
          })}
        </div>
        <br/>
        <h3 className="libraries-title">Products Section</h3>

        <div className="lflex-card">
          {(libraries.filter((e)=>e.category==="Products")).map((e) => {
            return (
              <NewLibraryCard
              image={e.image.url}
              title={e.title[0].text}
              id={e.title[0].text}

              article={e.article[0].text}
              date={e.date}
            />
            );
          })}
        </div>
      
      </div>
      <CTA />
      {width >= 1000 ? (
        <DesktopFooter
          address={training ? "/register" : "/book"}
          action={training ? "REGISTRATION" : "BOOK NOW"}
        />
      ) : (
        <Footer />
      )}
      <GoToTop />
    </>
  );
}

export default LibrariesService;
