import React, { useState, useEffect } from "react";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import "./CoursePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { newAbortSignal } from "../api/axios";
import BookDialogs from "../components/BookDialog";
import NavElementService from "../components/NavBar/NavElementService";
import GoToTop from "../functions/GotToTop";
import { useLocation } from "react-router-dom";
import { getLastWord, removeLastWord } from "../functions/HeaderTitleStyle";
import LibraryCard from "../components/LibraryCard";
import Slider from "react-slick";
import "./BlogPge.css";
import SlidLibraryCard from "../components/SlidLibrary";
import * as constants from "../utils/constants.js"
import NavElementTraining from "../components/NavBar/NavElementTraining";
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";
function BlogPage() {
  const { height, width } = useWindowDimensions();
  const check = <FontAwesomeIcon icon={faCheck} size="xs" />;
  const query = new URLSearchParams(useLocation().search);
  const [service, setservice] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [libraryLoaded, setLibraryLoaded] = useState(false);
  const [library, setLibrary] = useState([]);
  const LIBRARY_URL = "/api/training-libraries?populate=*";

  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  const location = useLocation();

  useEffect(() => {
    try {
      const id = query.get("id");
      console.log(id, location.pathname, "KKKKKKKKKKKKK")
      if (location.pathname === "/a-blog") {
        if (localStorage.getItem("training_library") !== null) {
          var retrievedObject = localStorage.getItem("training_library");
          setPageLoaded(true);
          setLibrary(JSON.parse(retrievedObject))
          setservice(JSON.parse(retrievedObject).filter((f) => f.title[0].text === id)[0]);
        } else {
          const fetchData = async () => {
            await getLandingPage().then((response) => {
              setPageLoaded(true);
              setLibrary(response.filter((e) => e.type === "training_library")[0].data.list)

              setservice(response.filter((e) => e.type === "training_library")[0].data.list.filter((f) => f.title[0].text === id)[0]);

            })
          }
          fetchData();
        }
      } else {
        if (localStorage.getItem("service_library") !== null) {
          var retrievedObject = localStorage.getItem("service_library");
          setPageLoaded(true);
          setLibrary(JSON.parse(retrievedObject))
          setservice(JSON.parse(retrievedObject).filter((f) => f.title[0].text === id)[0]);
        } else {
          const fetchData = async () => {
            await getLandingPage().then((response) => {
              setPageLoaded(true);
              setLibrary(response.filter((e) => e.type === "service_library")[0].data.list)
              setservice(response.filter((e) => e.type === "service_library")[0].data.list.filter((f) => f.title[0].text === id)[0]);
 
            })
          }
          fetchData();
          setLibraryLoaded(true);
        }
      }
    } catch (e) {
      setPageLoaded(true);
    }
  }, []); 
  const [slideNumber, setSlideNumber] = useState();

  useEffect(() => {
    if (width >= 1200) {
      setSlideNumber(5); 
    } else if (width > 930) { 
      setSlideNumber(4);
    } else if (width > 700) { 
      setSlideNumber(3);
    } else {  
      setSlideNumber(2);
    }
  }, [width]);
  var settings = {

    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000, 

  };
  const onSwap = async (id) => {
    try {


      window.scrollTo(0, 0)
      setservice(null) 
      if (location.pathname === "/a-blog") {
        if (localStorage.getItem("training_library") !== null) {
          
          var retrievedObject = localStorage.getItem("training_library");
          setPageLoaded(true);
          setservice(JSON.parse(retrievedObject).filter((f) => f.title[0].text === id)[0]);

          setPageLoaded(true);
        } else {
          const fetchData = async () => {
            await getLandingPage().then((response) => {
              setPageLoaded(true);
          console.log(response.filter((e) => e.type === "training_library")[0].data.list.filter((f) => f.title[0].text === id)[0], "KKKKKKKKKKKKK")

              setLibrary(response.filter((e) => e.type === "training_library")[0].data.list)

              setservice(response.filter((e) => e.type === "training_library")[0].data.list.filter((f) => f.title[0].text === id)[0]);
              setPageLoaded(true);
            })
          }
          fetchData();
        }
      } else {
        if (localStorage.getItem("service_library") !== null) {
          var retrievedObject = localStorage.getItem("service_library");
          setPageLoaded(true); 
          setservice(JSON.parse(retrievedObject).filter((f) => f.title[0].text === id)[0]);
          setPageLoaded(true);
        } else {
          const fetchData = async () => {
            await getLandingPage().then((response) => {
              setPageLoaded(true); 
              setservice(retrievedObject.filter((e) => e.type === "service_library")[0].data.list.filter((f) => f.title[0].text === id)[0]);
              setPageLoaded(true);

            })
          }
          fetchData();
        }
      } 
    } catch (e) {
      setPageLoaded(true);
    }
  }
  return (
    <>
      {location.pathname === "/a-blog" ?
        <NavElementTraining />
        : <NavElementService />
      }
      <div className="dark-60-bar" />
      {!service ? (
        <div className="loading-page">
          <div>{spinner}</div>
          <h2>Loading . . .</h2>
        </div>
      ) : (
        <div className="course-page">
          {width > 700 && (
            <div className="cp-header">
              <h1>Libraries</h1>
            </div>
          )}
          <div className="border-line">
            <div className="cp-image">
              {service && (
                <img 
                  src={service.image.url}

                  alt="skincare image" />
              )}
            </div>
            <div className="cp-title">
              <h1>{service && service.title[0].text}</h1>
            </div>
            <div className="cp-detail">
              <p>{service && service.article[0].text}</p>
            </div>
             
          </div>
        </div>
      )}{width < 700 && <>
        <br />
        <br />
      </>}
      <div className="sliding-library-blog">
        <Slider {...settings}>
          {library.length &&
            library.map((e) => {
              return (
                <SlidLibraryCard
                  image={e.image.url} 
                  title={e.title[0].text}
                  id={e.title[0].text}
                  date={e.date}
                  swapPage={() => onSwap(e.title[0].text)}
                />
              );
            })}

          {!library.length && (
            <div className="loading-header ">
              <div className="loading">{spinner}</div>
            </div>
          )}
        </Slider>
        <br />
        <br />
      </div>
      <div className="course-page"></div>
      <CTA />
      {width >= 1000 ? (
        <DesktopFooter address={"/book"} action={"BOOK NOW"} />
      ) : (
        <Footer />
      )}
      <GoToTop />
    </>
  );
}

export default BlogPage;

