import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Slider from "react-slick";  
import Togler from "./Togler";
import "./GlobalHero.css";
import axios from "../api/axios";
import "./CarousalHero.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GetLargeImage, GetMediumImage } from "../functions/GetTheRightImageQuality";
function CaraousalHero({ header }) {
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  const sliderRef = useRef();
  const textRef = useRef();
  const [page, setPage] = useState(1);
  const [current, setCurrentPage] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    beforeChange: (current, next) => {
      setCurrentPage(next);
    },
    onSwipe: () => {
      if (page == 2) {
        setPage(1);
      }
      if (page == 1) {
        setPage(2);
      }
    },
  };

  const onNext = () => {
    sliderRef.current.slickNext();
    if (page == 2) {
      setPage(1);
    }
    if (page == 1) {
      setPage(2);
    }
  };
  const onBack = () => {
    sliderRef.current.slickPrev();
    if (page == 2) {
      setPage(1);
    }
    if (page == 1) {
      setPage(2);
    }
  };

  // const [headers, setHeaders] = useState([]);
  // const LOGIN_URL = "/api/headers?populate=*";
  //   useEffect(() => {
  //   try {
  //     const response = async () => {
  //       const response = await axios.get(
  //         LOGIN_URL,
  //         JSON.stringify({ department: 1 }),
  //         {
  //           headers: {
  //             "Accept-Encoding": "gzip, deflate, br",
  //             Accept: "application/json",

  //           },
  //           withCredentials: true,
  //         }
  //       ).catch((error) => {
  //         console.log(error);
  //       });;
  //       console.log(response.data.data);
  //       setHeaders(response.data.data);
  //     };
  //     response();
  //   } catch (e) {}
  // }, []);
  const getLastWord = (words) => {
    var n = words.split(" ");
    return n[n.length - 1].toUpperCase();
  };
  const removeLastWord = (words) => {
    var n = words.trim().split(" ");
    var m = n.slice(0, -1);
    return m.join(" ");
  };
  const [visibelText, setVisibelText] = useState(false);

  useLayoutEffect(() => {

    if (header.length > 0) {
      setTimeout(() => {
        setVisibelText(true);
      }, 500);
    }
    //
  }, [header.length]);
  return (
    <>
     
      <div className="fit-width">
        <Slider ref={sliderRef} {...settings} className="slick-slider-destroy-gap">
          {header.length &&
            header.map((e) => {
              console.log(header,"JJJJJJJJJJJJJJJJJJJJJJJJJJJ")

              return (
                
                <div className="new-hero-layout ">
                   <div className="ddark-60-bar"></div> 
                  <div className="nh-shadow" />
                  <div className="nh-image">
                    <img src=   { e.image.url
                    // <img src=   {GetLargeImage(e.attributes.image.data)
} />
                    {/* <img src={e.attributes.image.data.attributes.url} /> */}
                  </div>
                  <div
                    className={
                      visibelText ? "nh-text visibleCarouselText" : "nh-text"
                    }
                    ref={textRef}
                  >
                    <h1>
                      {removeLastWord(e.text[0].text)}
                      {/* {removeLastWord(e.text.text)} */}
                      {/* {removeLastWord(e.attributes.title)} */}
                      <span>
                         {/* <br/> */}
                      {`  ${getLastWord(e.text[0].text)}`}
                      {/* {`  ${getLastWord(e.attributes.title)}`} */}
                      </span>
                    </h1>
                    
                  </div>
                  
                </div>
              );
            })}
            
          {!header.length && (
            <div className="loading-header ">
              <div className="loading-car">
                {spinner}
                {spinner}
                {spinner}
              </div>
            </div>
          )}
          {/* <div className="hero-page">
            <div className="ch-text">
              <h1>{title1}</h1> <h1>{title2} </h1>
              <h1>
                <span>{title}</span>
              </h1>
            </div>
            <div className="ch-image">
              <img src={surgery} />
            </div>
            <div className="tgl">
            </div>
          </div> */}
        </Slider>
        
        {header.length &&<div className="dToggler">
                      <Togler hero={true}
                        onForward={() => onNext()}
                        onBack={() => onBack()}
                        place={page}
                        current={current}
                        total={header.length ? header.length : 0}
                      />
                    </div>}
                  
      </div>
      {/* <hr className="solid-dark-alone" /> */}
    </>
  );
}

export default CaraousalHero;

// OLD LAY OUT
{
  /* <div className="hero-page make-row">
                  <div className="ch-text">
                    <h1>{e.attributes.title}</h1>
                  </div>
                  <div className="ch-image">
                    <img src={e.attributes.image.data.attributes.url} />
                  </div>
                  <div className="tgl"></div>
                </div> */
}
