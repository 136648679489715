import React from 'react'
import "./Button.css"
import {Link} from 'react-router-dom'

function OnClickButton({children,  buttonSize, buttonColor, onClick}) {
    const  onClickHandler =  ()=>{}
  return (
    <div className='btn-mobile no_highlights' onClick={()=>onClick()}>
        <button className={`btn ${buttonColor} ${buttonSize} `} onClick={onClickHandler}>
            {children}
        </button>
    </div>
  )
}

export default OnClickButton