import React, {useEffect, useState} from "react";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import image1 from "./assets/cert.png";
import LibraryCard from "../components/LibraryCard";
import "./Libraries.css";
import "./Cert.css";
import NavElementTraining from "../components/NavBar/NavElementTraining";
import GoToTop from "../functions/GotToTop";
import Slider from "react-slick";
function Cert({ training }) {
  const { height, width } = useWindowDimensions();
  const [slideNumber, setSlideNumber] = useState();

  
  var settings = {
    
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width > 1300 ? width > 2000 ?3:2 : 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    pauseOnHover:false, 
    // variableWidth:true,
    
  };
  return (
    <>
      {
             <NavElementTraining/>

      }
      <div className="dark-60-bar" />
      <div className="header-gap" />

      <div className="sectionTitle">
        <h2>Certification</h2>
      </div>
      <div className="glob-fit-width-flex">
      <div className="cert-section">
        <div className="cs-image">
          <img src={image1} alt="certificate" />
        </div>
        <div className="cert-text">
        {width<700&& 
        <>
        <br/>
          <br/></>
          }
            <h3>COC- Certification</h3>
            <h4>Students who successfully complete the Serum Masters Certification course will also receive the COC (Certificate of Competence) Certification. This certification holds significant value as it allows students to practice in the field of skincare in Ethiopia with official recognition of their competence and qualifications.</h4>
            {width<700&& 
        <>
        <br/>
          <br/></>
          }
            
            <h3>Authorization to practice</h3>
            <h4>Overall, the inclusion of the COC Certification as part of the Serum Masters Certification course offers graduates an added advantage in their skincare careers. It not only recognizes their achievements but also enables them to pursue their passion for skincare with official recognition and the necessary authorization to practice in Ethiopia.</h4>
        </div>
      </div>
      </div>
      <div className="padding-60"/>
  <div className="certsliding-library">
        <Slider {...settings}>
           
               
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694859555/medium_DSC_9655_4d4710284c.jpg"/>
                </div>
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694858680/medium_DSC_9645_5a8d83c4d8.jpg"/>
                </div>
                
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694859366/medium_DSC_9674_fe511862f4.jpg"/>
                </div>
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694860205/medium_DSC_9684_ddc19a7c11.jpg"/>
                </div>
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694860016/medium_DSC_9662_f9047d74fc.jpg"/>
                </div>
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694858666/medium_DSC_9646_0bb7216e45.jpg"/>
                </div>
                <div className="cert-library-image">
                  <img src="https://res.cloudinary.com/dhe0yelid/image/upload/v1694858660/medium_DSC_9637_d8929a6406.jpg"/>
                </div>
   
        </Slider> 
      </div> 
      <CTA buttonType={1}/>

      {width >= 1000 ? (
        <DesktopFooter
          address={training ? "/register" : "/book"}
          action={training ? "REGISTRATION" : "BOOK NOW"}
        />
      ) : (
        <Footer />
      )}
      <GoToTop/>

    </>
  );
}

export default Cert;
