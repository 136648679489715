import React, { useState, useEffect } from "react"; 
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CTA from "../components/CTA";
import DesktopFooter from "../components/DesktopFooter";
import Footer from "../components/Footer";
import useWindowDimensions from "../functions/useWindow";
import "./CoursePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Button";
import axios, { newAbortSignal } from "../api/axios";
import { useLocation, useParams } from "react-router-dom";
import CustomizedDialogs from "../components/MuiDialog";
import GoToTop from "../functions/GotToTop";
import NavElementTraining from "../components/NavBar/NavElementTraining";
import * as constants from "../utils/constants.js"
import { getLandingPage } from "../api/prismic";
 
function CoursePage({ courseId }) {
  const { height, width } = useWindowDimensions();
  const check = <FontAwesomeIcon icon={faCheck} size="xs" />;
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
 const query = new URLSearchParams(useLocation().search);
  const [service, setservice] = useState(null);
  useEffect(() => {
    try {
     
      const id = query.get("id");
      if (localStorage.getItem("courses") !== null) {
        var retrievedObject = localStorage.getItem("courses");
        setservice(JSON.parse(retrievedObject)[id]);
        
      }else{
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setservice(response.filter((e) => e.type === "trainings")[0].data.training_list[id]);

          })
        }
        fetchData();
      }
       
    } catch (e) {}
  }, []);
  const getLastItem = (list) => { 
    return list[list.length - 1];
  };
  const removeLastItem = (list) => { 
    var m = list.slice(0, -1);
    return m;
  };
  return (
    <>
      <NavElementTraining/>
      <div className="dark-60-bar" />
      {!service ? (
        <div className="loading-page">
          <div>{spinner}</div>
          <h2>Loading . . .</h2>
        </div>
      ) : (
        <div className="course-page">
          {width > 700 && (
            <div className="cp-header">
              <h1>Our Training Courses</h1>
            </div>
          )}
          <div className="border-line">
            <div className="cp-image">
              <img src={service && service.image.url} alt="image" />
            </div>
            <div className="cp-title">
              <h1>{service && service.title[0].text}</h1>
            </div>
            <div className="cp-detail">
              <p>{service && service.long_description[0].text}</p>
            </div>
            <div className="cp-item-list">
            {removeLastItem(service.list).map((e) => {
              if (e !== "")
                return (
                  <div className="single-cp-item">
                    <div className="check-mark">{check}</div>
                    {e.text}
                  </div>
                );
            })}
          </div>
          <div className="cp-detail">
              <p>{service && getLastItem(service.list).text}</p>
            </div>
            <br/>
            <br/> 
                 <CustomizedDialogs/>

          </div>
        </div>
      )}
      <CTA />
      {width >= 1000 ? (
        <DesktopFooter address={"/register"} action={"REGISTRATION"} />
      ) : (
        <Footer />
      )}
      <GoToTop/>

    </>
  );
}

export default CoursePage;
