import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./GallerySection.css"; 
import { ElfsightWidget } from "react-elfsight-widget";

function GallerySection() {
  const arrow = <FontAwesomeIcon icon={faArrowRight} />;

  return (
    <>
    {/* <hr className="solid-dark-alone" /> */}
{/* 
      <div className="sectionTitle top-padding-ten">
        <h2>Follow us on instagram </h2>
      </div>
      <div className="photo-grid">
        <div className="item-1 el">
          <img src={one} />
        </div>
        <div className="item-2 el">
          <img  src={two} />
        </div>
        <div className="item-3 el">
          <img  src={three} />
        </div>
        <div className="item-4 el">
          <img  src={four} />
        </div>
        <div className="item-5 el">
          <img src={one} />
        </div>
        <div className="item-6 el">
          <img  src={six} />
        </div>
        <div className="item-7 el">
          <img src={seven} />
        </div>
        <div className="item-8 el">
          <img  src={six} />
        </div>
        <div className="item-9 el">
          <img  src={nine} />
        </div>
        <div className="item-10 el">
          <img src={ten} />
        </div>
        <div className="item-11 el">
          <img src={eleven} />
        </div>
        <div className="item-12 el">
          <img  src={nine} />
        </div>
      </div>
      <div className="explore-more">
        <h4 className="cardLink center">Follow us on Instagram</h4>
        {arrow}
      </div> */}<div className="gal">
 <ElfsightWidget
          // style={{width:"90% !important"}}
          widgetId={"7cedf2c1-e0ef-4745-99f2-a6aea1e565cb"}
          lazy
        /></div>
    </>
  );
}

export default GallerySection;
