import React, { useRef, useEffect, useState } from "react";
import "./Home.css";
import Testimonials from "../components/Testimonials";
import CTA from "../components/CTA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BottomContact from "../components/BottomContact";
import HeroComp from "../components/HeroComp";
import DesktopFooter from "../components/DesktopFooter";
import "../components/Desktop.css";
import ServicesHero from "../components/ServicesHero";
import GlobalHero from "../components/GlobalHero";
import GallerySection from "../components/GallerySection";
import CaraousalHero from "../components/CaraousalHero";
import useWindowDimensions from "../functions/useWindow";
import CardForCourse from "../components/cards/CardForCourse";
import axios, { newAbortSignal } from "../api/axios";
import Chatbox from "../components/bot/chatbox";
import ReactGA from "react-ga";
import VideoSection from "../components/VideoSection";
import Button from "../components/Button";
import { Link, useLocation } from "react-router-dom";
import ServiceNavBar from "../components/NavBar/ServiceNavBar";
import CustomizedDialogs from "../components/MuiDialog";
import NavElementTraining from "../components/NavBar/NavElementTraining";
import GoToTop from "../functions/GotToTop";
import { getLastWord, removeLastWord } from "../functions/HeaderTitleStyle";
import MobileCarousalHero from "../components/MobileCarousalHero";
import * as constants from "../utils/constants.js";
import { GetMediumImage } from "../functions/GetTheRightImageQuality";
import { getLandingPage } from "../api/prismic";
function Home() {
  const [headerLoaded, setHeaderLoaded] = useState(false);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [testimonyLoaded, setTestimonyLoaded] = useState(false);
  const location = useLocation();
  const arrow = <FontAwesomeIcon icon={faArrowRight} />;
  const spinner = (
    <FontAwesomeIcon icon={faSpinner} className="spinner" size="2xl" />
  );
  const ref = useRef();
  const { height, width } = useWindowDimensions();

  //// ON WIDTH CHNGE

  useEffect(() => {
    if (width >= 700) {
      if (
        localStorage.getItem("training-header") !== null &&
        trainingHeader == []
      ) {
        var retrievedObject = localStorage.getItem("training-header");
        setTrainingHeader(JSON.parse(retrievedObject));
      } else if (localStorage.getItem("training-header") === null) {
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setTrainingHeader(response.filter((e) => e.type === "training_desktop_header")[0].data.list);
            setHeaderLoaded(true);

          })
        }
        fetchData();
      }
    } else {
      if (
        localStorage.getItem("training-mobile-header") !== null &&
        mobileHeader == []
      ) {
        var retrievedObject = localStorage.getItem("training-mobile-header");
        setMobileHeader(JSON.parse(retrievedObject));
        setMobileHeaderLoaded(true);
        setHeaderLoaded(true);
      } else if (localStorage.getItem("training-mobile-header") === null) {
        const fetchData = async () => {
          await getLandingPage().then((response) => {
            console.log(response.filter((e) => e.type === "training_mobile_header")[0].data.list,"PAAAAAAAAAAAAAARSED");

            setMobileHeader(response.filter((e) => e.type === "training_mobile_header")[0].data.list);
            setHeaderLoaded(true);
            
          })
        }
        fetchData();
      }
    }
  }, [width]);

  //// GET HERO SECTION
  const [trainingHeader, setTrainingHeader] = useState([]);
  const TRAININGHEADER_URL = "/api/training-headers?populate=*";
  const [mobileHeader, setMobileHeader] = useState([]);
  const [mobileHeaderLoaded, setMobileHeaderLoaded] = useState(false);
  const MOBILE_HERO_URL = "/api/training-mobile-headers?populate=*";
  useEffect(() => {
    try {
      if (localStorage.getItem("training-header") !== null && width >= 700) {
        var retrievedObject = localStorage.getItem("training-header");
        setTrainingHeader(JSON.parse(retrievedObject));
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("training-header") === null &&
        width >= 700
      ) {
        const fetchData = async () => {
          await getLandingPage().then((response) => {

            setTrainingHeader(response.filter((e) => e.type === "training_desktop_header")[0].data.list);
            setHeaderLoaded(true);

          })
        }
        fetchData();
      }
      //PHONE
      else if (
        localStorage.getItem("training-mobile-header") !== null &&
        width < 700
      ) {

        var retrievedObject = localStorage.getItem("training-mobile-header");
        setMobileHeader(JSON.parse(retrievedObject));
        setMobileHeaderLoaded(true);
        setHeaderLoaded(true);
      } else if (
        localStorage.getItem("training-mobile-header") === null &&
        width < 700
      ) {

        const fetchData = async () => {
          await getLandingPage().then((response) => {
            console.log(response.filter((e) => e.type === "training_mobile_header")[0].data.list,"PAAAAAAAAAAAAAARSED");

            setMobileHeader(response.filter((e) => e.type === "training_mobile_header")[0].data.list);
            setHeaderLoaded(true);
            
          })
        }
        fetchData();
      }
    } catch (e) {
      setHeaderLoaded(true);
    }
  }, []);

  /// GET COURSES
  const [courses, setCourses] = useState([]);
  const LOGIN_URL = "/api/programs?populate=*";
  useEffect(() => {
    if (headerLoaded) {
      try {
        if (localStorage.getItem("courses") !== null) {
          var retrievedObject = localStorage.getItem("courses");
          setCourses(JSON.parse(retrievedObject));
          setCourseLoaded(true);
          
        }else{
          const fetchData = async () => {
            await getLandingPage().then((response) => {
  
              setCourses(response.filter((e) => e.type === "trainings")[0].data.training_list);
              setCourseLoaded(true);
  
            })
          }
          fetchData();
        }
      } catch (e) {
        setCourseLoaded(true);
      }
    }
  }, [headerLoaded]);

  /// GET TESTIMONY
  const [testimonies, setTestimonies] = useState([]);
  const TESTIMONY_URL = "/api/testimonies?populate=*";
  useEffect(() => {
    if (courseLoaded) {
      try {
        if (localStorage.getItem("testimony") !== null) {
          var retrievedObject = localStorage.getItem("testimony");
          setTestimonies(JSON.parse(retrievedObject));
          setTestimonyLoaded(true);
          
        }else{
          const fetchData = async () => {
            await getLandingPage().then((response) => {
  
              setTestimonies(response.filter((e) => e.type === "testimony")[0].data.list);
              setTestimonyLoaded(true);
  
            })
          }
          fetchData();
        }
        // const response = async () => {
        //   await axios
        //     .get(TESTIMONY_URL, {
        //       signal: newAbortSignal(constants.TIMEOUT_LONG),
        //     })
        //     .then((response) => {
        //       console.log(response.data.data);
        //       setTestimonies(response.data.data);
        //       setTestimonyLoaded(true);
        //     })
        //     .catch((e) => {
        //       setTestimonyLoaded(true);
        //       console.log(e);
        //     });
        // };
        // response();
      } catch (e) {
        setTestimonyLoaded(true);
      }
    }
  }, [courseLoaded]);
  const [visibelText, setVisibelText] = useState();
  useEffect(() => {
    setTimeout(() => {
      setVisibelText(true);
    }, 500);
  }, [headerLoaded]);
  useEffect(() => {
    if (testimonyLoaded) {
      ReactGA.pageview("/training");
    }
  }, [testimonyLoaded]);
  useEffect(() => {
    if (testimonyLoaded) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }
  }, [testimonyLoaded]);

  const [isWidgetLoaded, setIsWidgetLoaded] = useState(true);

  const handelVideoSectionIsLoaded = () => {
    setIsWidgetLoaded(true);
  };
  return (
    <>
      <NavElementTraining />
      {width >= 700 ? (
        <CaraousalHero header={trainingHeader} />
      ) : (
        <MobileCarousalHero header={mobileHeader} training={true} />
      )}

      {width > 700 ? (
        <div className={"elfwidget"}>
          <div className="video-container">
            <VideoSection
              isLoadedFunction={() => handelVideoSectionIsLoaded()}
            />

            {isWidgetLoaded && (
              <div className="overlay">
                <h2>TEACHING THE SCIENCE OF  <span>BEAUTIFUL SKIN</span></h2>
                {/* <p>DESCRIPTION</p> */}
                <div className="video-buttons">
                  <a href="tel:+251911615776">
                    <Button
                      contactButton={true}
                      className="btn desktop-button"
                      children="Contact Us"
                      buttonColor="btn--dark"
                      buttonSize="btn--big"
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="header-gap-not-smaller" />

          <VideoSection isLoadedFunction={() => handelVideoSectionIsLoaded()} />
        </>
      )}

      <div className="global-component-padding">
        <div
          className="sectionTitle TsectionTitle"
          style={{ paddingBottom: width >= 700 ? "50px" : "1px" }}
        >
          <h2>Training Programs </h2>
          {width < 700 && <><br /></>}
          {!courses.length && width >= 700 ? (
            <div className="loading">
              {spinner} {spinner} {spinner}
            </div>
          ) : null}
          {!courses.length && width < 700 ? (
            <div className="loading"> {spinner}</div>
          ) : null}
        </div>
        {courses.length == 0 ? null : (
          <div className="c-s-div">
            <div className="coursesSection">
              {courses.map((e) => {
                return (
                  <CardForCourse
                    // image={e.attributes.image.data.attributes.url}
                    // image={GetMediumImage(e.attributes.image.data)}
                    image={e.image.url}

                    title={e.title[0].text}
                    detail={e.short_description[0].text}
                    id={courses.indexOf(e)}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* <div className="explore-more">
        <Link to={"/programs"} className="link">
         
          <h4 className="cardLink center">Explore more courses</h4>
        </Link>
        {arrow}
      </div> */}
      {width >= 1000 ? (
        <div className="global-component-padding elfwidget">
          <div className="sectionTitle">
            <h2>Follow us on Instagram</h2>
          </div>
          <GallerySection />
        </div>
      ) : null}
      {width < 700 && (
        <>
          <br />
          <br />
          <br />
        </>
      )}
      {testimonyLoaded && testimonies.length && (
        <div className="global-component-padding">
          <Testimonials testimonies={testimonies} />
        </div>
      )}
      <CTA buttonType={1} />
      {width >= 1000 ? (
        <DesktopFooter address={"/register"} action={"REGISTRATION"} />
      ) : (
        <Footer />
      )}
      <GoToTop />
    </>
  );
}
export default Home;
